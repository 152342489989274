<template>
  <ion-page>
    <ion-header :translucent="false">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title class="font-bold">Post Work Checklist</ion-title>
        <span slot="end" class="pr-5">
                    <user-chip></user-chip>
                </span>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Blank</ion-title>
        </ion-toolbar>
      </ion-header>

      <div id="container">
        <camera v-if="cameraOpen" @cameraClosed="CameraClosed" :type="photoType" :current-images="currentImages"
                :folder-id="form.folderId" check-type="Post Work" :jobid="form.jobId"
                :required-images="requiredImages"></camera>
        <div v-show="!cameraOpen" class="min-h-screen h-full" id="top">
          <q-card class="">
            <template #header>
              <div class="text-2xl font-bold">General</div>
            </template>
            <template #main class="q-pt-none">
              <field-section class="mt-4" title="Registration Number">
                <q-input v-model="v$.form.reg.$model" :v="v$.form.reg"/>
              </field-section>

              <field-section class="mt-10" title="Customer Name">
                <q-input v-model="v$.form.name.$model" :v="v$.form.name"/>
              </field-section>

              <field-section class="mt-10" title="Notes">
                <q-editor v-model="v$.form.notes.$model" :v="v$.form.notes" min-height="5rem"/>
              </field-section>

              <field-section class="mt-10" title="Service Book Stamped">
                <button-radio :options="['Yes', 'No', 'Not Present']" v-model="v$.form.serviceBookStamped.$model"
                              :v="v$.form.serviceBookStamped"></button-radio>
              </field-section>

              <field-section class="mt-10" title="Mileage In">
                <q-input type="number" v-model="v$.form.mileageIn.$model" :v="v$.form.mileageIn"
                         :readonly="form.hasMileage == true"/>
              </field-section>

              <field-section class="mt-10" title="Mileage Out">
                <q-input type="number" v-model="v$.form.mileageOut.$model" :v="v$.form.mileageOut"
                         @input="UpdateNextServiceMiles"/>
              </field-section>

              <field-section class="mt-10" title="Next Service Due (date)">
                <date-pick v-model="v$.form.nextServiceDueDate.$model" :v="v$.form.nextServiceDueDate"></date-pick>
              </field-section>

              <field-section class="mt-10" title="Next Service Due (miles)">
                <q-input type="number" v-model="v$.form.nextServiceDueDistance.$model"
                         :v="v$.form.nextServiceDueDistance"/>
              </field-section>

              <field-section class="mt-10" title="Full Fault Code Scan - After">
                <button-check :options="['Complete - Saved to Google drive']"
                              v-model="v$.form.faultCodeScanComplete.$model"
                              :v="v$.form.faultCodeScanComplete"></button-check>
              </field-section>

              <field-section class="mt-10" title="Service Light Reset">
                <button-radio :options="['No', 'Yes']" v-model="v$.form.serviceLightReset.$model"
                              :v="v$.form.serviceLightReset"></button-radio>
              </field-section>

              <field-section class="mt-10" title="Low fluids from Pre-work topped up">
                <button-radio :options="['Yes', 'No', 'Not Needed']" v-model="v$.form.lowFluidsToppedUp.$model"
                              :v="v$.form.lowFluidsToppedUp"></button-radio>
              </field-section>

              <field-section class="mt-10" title="Old Parts">
                <button-radio :options="['Customer to Keep', 'Scrap', 'Sell to SRS', 'Exchange Parts']"
                              v-model="v$.form.oldParts.$model" :v="v$.form.oldParts"></button-radio>
              </field-section>

              <field-section class="mt-10" title="Engine Cover">
                <button-radio :options="['Fitted', 'In Boot', 'Damaged Fixings', 'Missing']"
                              v-model="v$.form.engineCover.$model" :v="v$.form.engineCover"></button-radio>
              </field-section>

              <field-section class="mt-10" title="Undertray">
                <button-radio :options="['Fitted', 'In Boot', 'Damaged Fixings', 'Missing']"
                              v-model="v$.form.undertray.$model" :v="v$.form.undertray"></button-radio>
              </field-section>

              <field-section class="mt-10" title="Check fans plugged in">
                <button-radio :options="['No', 'Yes']" v-model="v$.form.checkFansPluggedIn.$model"
                              :v="v$.form.checkFansPluggedIn"></button-radio>
              </field-section>

              <field-section class="mt-10" title="Lights on Dashboard Check">
                <div>
                  <colour-select :options="[{color:'Green', value:'No'},{color:'Red', value:'Yes'}]"
                                 v-model="v$.form.lightsOnDashboardCheck.$model" padding
                                 :v="v$.form.lightsOnDashboardCheck"/>
                  <div v-if="v$.form.lightsOnDashboardCheck.$model == 'Yes'"
                       class="flex flex-wrap gap-3 mt-2 justify-center">
                    <div
                        class="cursor-pointer text-center w-1/5 border border-gray-400 p-2 transition ease-in-out duration-300"
                        v-for="light in dashlights" :key="light.id" @click="AddLightToList(light)"
                        :class="GetDashLightClass(light)">
                      <img class="mx-auto" :src="light.path">
                      <p class="select-none text-lg">{{ light.name }}</p>
                    </div>
                  </div>
                </div>
              </field-section>

              <field-section class="mt-10" title="Gears">
                <template #warning>Check vehicle goes into all gears, listen for noises etc</template>
                <div class="flex gap-3 w-full">
                  <colour-select padding v-model="v$.form.cycleGears.$model" :v="v$.form.cycleGears"></colour-select>
                  <q-input class="w-full" v-model="v$.form.cycleGearsDesc.$model" :v="v$.form.cycleGearsDesc"/>
                </div>
              </field-section>
            </template>
          </q-card>

          <q-card class="mt-3">
            <template #header>
              <div class="text-2xl font-bold">Full Spanner Check</div>
            </template>
            <template #main class="q-pt-none">
              <field-section class="mt-4" title="Torque Wheels">
                <button-check :options="['Torque Wheels']" v-model="v$.form.torqueWheels.$model"
                              :v="v$.form.torqueWheels"></button-check>
              </field-section>

              <field-section class="mt-4" title="Checked">
                <button-check :options="['Checked']" v-model="v$.form.isChecked.$model"
                              :v="v$.form.faultCodeScanComplete"></button-check>
              </field-section>

              <field-section class="mt-4" title="Mechanic signature (Checked)">
                <sigpad ref="mechSig"></sigpad>
              </field-section>

              <field-section class="mt-10" title="Double Checked">
                <button-check :options="['Double Checked']" v-model="v$.form.isDoubleChecked.$model"
                              :v="v$.form.isDoubleChecked"></button-check>
              </field-section>

              <field-section class="mt-4" title="Mechanic signature (Double Checked)">
                <sigpad ref="mechSig2"></sigpad>
              </field-section>

              <!--<div id="photos"></div>
              <field-section class="mt-10" title="Photos">
                  <multi-image-capture ref="imageCapture" type="Post Work"/>
              </field-section>-->

              <PhotoCapture v-for="img in externalPhotos" :key="img.id" :img="img" @image-taken="exteriorImageTaken"
                            type="Post Work" :jobid="form.jobId" :photos="form.exteriorPhotos"></PhotoCapture>

              <field-section class="mt-10" title="Completed">
                <button-check :options="['Complete - Saved to Google drive']" v-model="v$.form.isCompleted.$model"
                              :v="v$.form.isCompleted"></button-check>
              </field-section>

              <field-section class="mt-10" title="Keys">
                <button-radio :options="['In Office', 'In Key Cupboard']" v-model="v$.form.keyLocation.$model"
                              :v="v$.form.keyLocation"></button-radio>
              </field-section>

              <field-section class="mt-10" title="Checklist Notes">
                <div class="border border-color p-2 rounded">
                  <ul id="generatedNotes">
                    <li v-for="(note,index) in generatedNotes" :key="index">{{ ConvertNote(note) }}</li>
                    <li v-for="(note,index) in generatedNotes.dashlights" :key="index">Lights on Dashboard -
                      {{ note.name }} - {{ note.type }}
                    </li>
                  </ul>
                </div>
              </field-section>
            </template>
          </q-card>

          <!--<q-card class="mt-3">
              <template #header>
                  <div class="flex items-center">
                      <div class="text-2xl font-bold">Costs</div>
                      <q-btn color="primary" class="ml-auto" size="small">Full parts list to be fitted & Costs</q-btn>
                  </div>
              </template>

              <template #main class="q-pt-none">
                  <div :class="layout">
                      <label class="font-semibold text-lg">Full parts list to be fitted - Confirmed?</label>
                      <button-radio :options="['Yes', 'No']" v-model="v$.form.partsConfirmed.$model" :v="v$.form.partsConfirmed"></button-radio>
                  </div>

                  <div class="mt-5" :class="layout">
                      <label class="font-semibold text-lg">Authorised Extra Costs (above what is already confirmed) before phonecall / email required</label>
                      <q-input prefix="£" type="number" v-model="v$.form.authorisedCosts.$model" :v="v$.form.authorisedCosts" />
                  </div>

                  <div class="mt-5" :class="layout">
                      <label class="font-semibold text-lg">Deposit Paid</label>
                      <q-input prefix="£" type="number" v-model="v$.form.deposit.$model" readonly />
                  </div>
              </template>
          </q-card>

          <q-card class="mt-3">
              <template #header>
                  <div class="flex items-center">
                      <div class="text-2xl font-bold">Tuning</div>
                      <q-btn color="primary" class="ml-auto" size="small">Tuning Job Details</q-btn>
                  </div>
              </template>

              <template #main class="q-pt-none">
                  <div :class="layout">
                      <label class="font-semibold text-lg">Tuning Job Details - Confirmed?</label>
                      <button-radio :options="['Yes', 'No']" v-model="v$.form.tuningConfirmed.$model" :v="v$.form.tuningConfirmed"></button-radio>
                  </div>

                  <div class="mt-5" :class="layout">
                      <label class="font-semibold text-lg">Expected Power (BHP)</label>
                      <q-input type="number" v-model="v$.form.expectedPower.$model" :v="v$.form.expectedPower" />
                  </div>

                  <div class="mt-5" :class="layout">
                      <label class="font-semibold text-lg">Smoke Level</label>
                      <button-radio :options="['Smoke Free', 'Light Haze', 'Mid-Smoke', 'Heavy Smoke', 'No Preference']" v-model="v$.form.smokeLevel.$model" :v="v$.form.smokeLevel"></button-radio>
                  </div>

                  <div class="mt-5" :class="layout">
                      <label class="font-semibold text-lg">Hardcut Limiter</label>
                      <button-radio :options="['Yes', 'No']" v-model="v$.form.hardcut.$model" :v="v$.form.hardcut"></button-radio>
                      <q-input class="mt-2" v-model="v$.form.hardcutRpm.$model" placeholder="RPM..." v-if="v$.form.hardcut.$model == 'Yes'" />
                  </div>

                  <div class="mt-5" :class="layout">
                      <label class="font-semibold text-lg">Launch Control</label>
                      <button-radio :options="['Yes', 'No']" v-model="v$.form.launch.$model" :v="v$.form.launch"></button-radio>
                      <q-input class="mt-2" v-model="v$.form.launchRpm.$model" placeholder="RPM..." v-if="v$.form.launch.$model == 'Yes'" />
                  </div>

                  <div class="mt-5" :class="layout">
                      <label class="font-semibold text-lg">Customer Present During Check</label>
                      <button-radio :options="['Present', 'Not Present', '3rd Party']" v-model="v$.form.customerPresent.$model" :v="v$.form.customerPresent"></button-radio>
                      <div class="bg-red-400 p-3 mt-1 rounded text-white" v-if="v$.form.customerPresent.$model === 'Not Present'">
                          I CONFIRM THAT I AM HAPPY TO LEAVE MY CAR WITH DARKSIDE DEVELOPMENTS WITHOUT WITNESSING THE VEHICLE CHECK
                      </div>

                      <div class="bg-red-400 p-3 mt-1 rounded text-white" v-if="v$.form.customerPresent.$model === '3rd Party'">
                          3RD PARTY LEFT VEHICLE FOR COLLECTION, CUSTOMER NO PRESENT DURING CHECKS
                      </div>
                  </div>
              </template>
          </q-card>

          <q-card class="mt-3" id="ext_checks">
              <template #header>
                  <div class="text-2xl font-bold">Exterior Checks</div>
              </template>

              <template #main class="q-pt-none">
                  <q-btn color="primary" icon="camera_alt" @click="OpenCamera('exterior')">Take Pictures <span class="ml-3 bg-white h-5 w-5 text-gray-900 flex items-center justify-center rounded-full text-xs" v-if="form.exteriorPhotos.length > 0">{{form.exteriorPhotos.length}}</span></q-btn>

                  <div class="mt-5" :class="layout">
                      <label class="font-semibold text-lg">General Condition of Tyres</label>
                      <div>
                          <colour-select v-model="v$.form.generalConditionOfTyres.$model" padding />
                          <q-input v-model="v$.form.generalConditionOfTyresDesc.$model" class="mt-2" :v="v$.form.generalConditionOfTyres" placeholder="Tyre notes..." />

                          <div v-if="v$.form.generalConditionOfTyres.$model == 'Red' || v$.form.generalConditionOfTyres.$model == 'Orange'" class="mt-2 w-full">
                              <label class="font-semibold text-lg">Tyre Size</label>
                              <div class="flex items-center gap-2">
                                  <q-select :options="tyreDepths" v-model="v$.form.tyreWidth.$model" style="width:100px" :v="v$.form.tyreWidth"></q-select>
                                  /
                                  <q-select :options="tyreWidths" v-model="v$.form.tyreDepth.$model" style="width:100px" :v="v$.form.tyreDepth"></q-select>
                                  /
                                  <q-select :options="tyreSizes" v-model="v$.form.tyreDiameter.$model" style="width:100px" :v="v$.form.tyreDiameter" prefix="R"></q-select>
                              </div>
                              <label class="font-semibold text-lg mt-2 block">Tyre Brand & Model</label>
                              <q-input v-model="v$.form.tyreBrand.$model" :v="v$.form.tyreBrand" />
                          </div>
                      </div>
                  </div>
              </template>
          </q-card>

          <q-card class="mt-3">
              <template #header>
                  <div class="text-2xl font-bold">Interior Checks</div>
              </template>

              <template #main class="q-pt-none">
                  <q-btn color="primary" icon="camera_alt" @click="OpenCamera('interior')">Take Pictures <span class="ml-3 bg-white h-5 w-5 text-gray-900 flex items-center justify-center rounded-full text-xs" v-if="form.interiorPhotos.length > 0">{{form.interiorPhotos.length}}</span></q-btn>

                  <div class="mt-5" :class="layout">
                      <label class="font-semibold text-lg">Lights on Dashboard Check</label>
                      <colour-select :options="[{color:'Green', value:'No'},{color:'Red', value:'Yes'}]" v-model="v$.form.lightsOnDashboardCheck.$model" padding :v="v$.form.lightsOnDashboardCheck" />
                      <div v-if="v$.form.lightsOnDashboardCheck.$model == 'Yes'" class="flex gap-3 mt-2 justify-center">
                          <div class="cursor-pointer text-center w-1/5 border border-gray-400 p-2 transition ease-in-out duration-300" v-for="light in dashlights" :key="light.id" @click="AddLightToList(light)" :class="GetDashLightClass(light)">
                              <img class="mx-auto" :src="light.path">
                              <p class="select-none text-lg">{{light.name}}</p>
                          </div>
                      </div>
                  </div>

                  <div class="mt-5" :class="layout">
                      <label class="font-semibold text-lg">Service Indicator Present</label>
                      <button-radio :options="['Yes', 'No']" v-model="v$.form.serviceIndicatorPresent.$model" :v="v$.form.serviceIndicatorPresent"></button-radio>
                  </div>

                  <div class="mt-5" :class="layout">
                      <label class="font-semibold text-lg">Current Mileage</label>
                      <q-input v-model="v$.form.currentMileage.$model" :v="v$.form.currentMileage" />
                  </div>

                  <div class="mt-5" :class="layout">
                      <label class="font-semibold text-lg">Turbo Noise</label>
                      <colour-select :options="[{color:'Green', value:'Green'},{color:'Yellow', value:'Yellow'},{color:'Orange', value:'Orange'},{color:'Red', value:'Red'}]" v-model="v$.form.turboNoise.$model" :v="v$.form.turboNoise" padding />
                  </div>
              </template>
          </q-card>

          <q-card class="mt-3">
              <template #header>
                  <div class="text-2xl font-bold">Others</div>
              </template>

              <template #main class="q-pt-none">
                  <div class="" :class="layout">
                      <label class="font-semibold text-lg">Checklist Notes</label>
                      <div class="border border-color p-2 rounded">
                          <ul>
                              <li v-for="(note,index) in generatedNotes" :key="index">{{ConvertNote(note)}}</li>
                          </ul>
                      </div>
                  </div>

                  <div class="mt-5" :class="layout">
                      <label class="font-semibold text-lg">Notes</label>
                      <q-editor v-model="v$.form.notes.$model" :v="v$.form.notes" min-height="5rem" />
                  </div>
              </template>
          </q-card>

          <q-card class="mt-3">
              <template #header>
                  <div class="text-2xl font-bold">Customer</div>
              </template>

              <template #main class="q-pt-none">
                  <help-text class="mt-5">
                      Secure the visual sign off by the client/customer once all photos and data capture is complete.
                  </help-text>

                  <div class="mt-5">
                      <p class="font-bold">I, THE CUSTOMER, CONFIRM ALL THE INFORMATION IN THIS FORM IS CORRECT AND AGREE TO THE <a class="text-blue-500" href="http://www.darksidedevelopments.co.uk/terms-and-conditions#Vehicle_drop_off_Workshop_Tuning" target="_blank">TERMS AND CONDITIONS</a> THAT WERE EMAILED TO ME AT THE TIME OF BOOKING</p>
                  </div>

                  <div class="mt-5">
                      <label class="font-semibold text-lg">Customer Signature</label>
                      <sigpad @signature-saved="SaveSignature"></sigpad>
                  </div>

                  <div class="mt-5">
                      <p class="font-bold">INFORMATION IS FOR DARKSIDE DEVELOPMENTS USE ONLY AND WILL NOT BE SHARED WITH ANY OTHER COMPANIES</p>
                  </div>

                  <help-text class="mt-5">
                      Advise the client/customer to please take a seat in reception whilst we undertake the vehicle road test, allocate road test to the appropriate member of the team. (Tuning or Service Workshop).
                  </help-text>
              </template>
          </q-card>-->

          <div class="pt-3 pb-3 px-2 w-full">
            <q-btn expand="block" @click="Submit">SUBMIT</q-btn>
          </div>

          <q-card v-if="errors.length > 0">
            <template #header>
              <div class="text-2xl font-bold">Errors</div>
            </template>
            <template #main class="q-pt-none">
              <div>
                <ul>
                  <li v-for="(err, index) in errors" :key="index">
                    <span class="inline-block mb-2 font-bold text-red-500">{{ err.field }} - </span> <span
                      class="inline-block w-32">{{ err.msg }}</span>
                  </li>
                </ul>
              </div>
            </template>
          </q-card>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import moment from 'moment';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  loadingController,
  alertController
} from '@ionic/vue';
import {
  defineComponent
} from 'vue';
import useVuelidate from '@vuelidate/core'
import {
  required,
  requiredIf,
  email,
  minValue,
  numeric
} from '@vuelidate/validators'
import camera from '../../components/Camera.vue'
import {useMainStore} from "@/store/mainStore";
import {usePostWorkStore} from "@/store/postWorkStore";
import MultiImageCapture from "@/components/MultiImageCapture.vue";
import PhotoCapture from "@/components/PhotoCapture.vue";
import router from "@/router";

export default defineComponent({
  name: 'Home',
  components: {
    PhotoCapture,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    camera
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      layout: "blocked",
      photoType: null,
      currentImages: [],
      loading: false,
      cameraOpen: false,
      jobs: [],
      jobIdOptions: [],
      selectedJob: null,
      jobLoaded: false,
      operators: [],
      staff: [],
      generatedNotes: [],
      dashlights: [],
      customerLocations: ["Leaving - Collect same day", "Leaving - Collect when done", "Waiting in Reception", "Staying in Hotel"],
      tyreWidths: ['135', '140', '145', '150', '155', '160', '165', '170', '175', '180', '185', '190', '195', '200', '205', '210', '215', '220', '225', '230', '235', '240', '245', '250', '255', '260', '265', '270', '275', '280', '285', '290', '295', '300', '305', '310', '315', '320', '325'],
      tyreDepths: ['25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85'],
      tyreSizes: ['13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
      requiredImages: [],
      form: usePostWorkStore(),
      errors: [],
      externalPhotos: [
        {id: 1, text: "Clocks while running"},
        {id: 2, text: "Interior Front"},
        {id: 3, text: "Interior Rear"},
        {id: 4, text: "Boot"},
        {id: 5, text: "Windscreen"},
        {id: 6, text: "Front Drivers Corner"},
        {id: 7, text: "Front Passenger Corner"},
        {id: 8, text: "Passenger Side"},
        {id: 9, text: "Rear Passenger Corner"},
        {id: 10, text: "Rear"},
        {id: 11, text: "Rear Drivers Corner"},
        {id: 12, text: "Drivers Side"},
        {id: 13, text: "Under Bonnet"},
        {id: 14, text: "Under Bonnet 2"},
        {id: 15, text: "Undertray Installed"}
      ],
    }
  },
  validations() {
    return {
      form: {
        reg: {
          required
        },
        name: {
          required
        },
        checkCompletedBy: {
          required
        },
        bookingFormID: {
          // required
        },
        notes: {
          required
        },
        hasMileage: {},
        serviceBookStamped: {required},
        mileageIn: {required},
        mileageOut: {required},
        nextServiceDueDate: {required},
        nextServiceDueDistance: {required},
        faultCodeScanComplete: {required},
        serviceLightReset: {required},
        lowFluidsToppedUp: {required},
        oldParts: {required},
        engineCover: {required},
        undertray: {required},
        checkFansPluggedIn: {required},
        lightsOnDashboardCheck: {required},
        cycleGears: {required},
        cycleGearsDesc: {},
        isChecked: {required},
        isDoubleChecked: {required},
        isCompleted: {required},
        torqueWheels: {required},
        keyLocation: {required},
        mechanicSig: {required},
        mechanicSig2: {required}
      }
    }
  },
  async mounted() {
    this.form.exteriorPhotos = [];
    await this.presentLoading();
    this.form.nextServiceDueDate = moment().toISOString()
    this.form.checkCompletedBy = useMainStore().authUser.value
    this.form.jobId = this.$router.currentRoute.value.params.id
    this.GetStaff().then(() => {
      this.GetJobById(this.$router.currentRoute.value.params.id)
    })
    for (const k in this.form) {
      this.$watch('form.' + k, (val, oldVal) => {
        this.NoteUpdater(k, val, oldVal);
      })
    }
  },
  methods: {
    UpdateLayout(a) {
      var val = a.detail.checked;
      if (val) {
        this.layout = "condensed";
      } else {
        this.layout = "blocked";
      }
    },
    OpenCamera(type) {
      this.photoType = type;
      this.requiredImages = [
        {id: 1, text: "Clocks while Running"},
        {id: 2, text: "Interior Front"},
        {id: 3, text: "Interior Rear"},
        {id: 4, text: "Boot"},
        {id: 5, text: "Windscreen"},
        {id: 6, text: "Front Drivers Corner"},
        {id: 7, text: "Front Passenger Corner"},
        {id: 8, text: "Passenger Side"},
        {id: 9, text: "Rear Passenger Corner"},
        {id: 10, text: "Rear"},
        {id: 11, text: "Rear Drivers Corner"},
        {id: 12, text: "Drivers Side"},
        {id: 13, text: "Under Bonnet"},
        {id: 14, text: "Under Bonnet 2"},
        {id: 15, text: "Undertray Installed"},
        {id: 16, text: "Extra"}
      ];
      this.currentImages = this.form.photos
      this.cameraOpen = !this.cameraOpen
    },
    CameraClosed(images) {
      this.cameraOpen = false
      this.form.photos.concat(images)
      this.photoType = null;
      setTimeout(() => {
        this.handleScroll();
      }, 500);
    },
    GetJobById(id) {
      const jobid = id;
      this.$axios.get(this.$apiUrl + '/v2/checklist/job/' + jobid).then(async (resp) => {
        await this.$axios.get(this.$apiUrl + '/v2/checklist/job/folder/' + jobid).then(response => {
          if (response.data && response.data.success) {
            this.form.folderId = response.data.message;
          } else {
            this.form.folderId = this.$checklistTempFolder;//'0B-RqqvwYVL1FYnA5VktBWjhiQlU';
          }
        })
        this.Populate(resp.data);
        await this.loading.dismiss()
      }).catch(async () => {
        await this.loading.dismiss()
      })
    },
    Populate(job) {
      if (job.folder_id === null || job.folder_id === '') {
        alert('NO FOLDER FOR JOB');
      }
      this.jobLoaded = true;
      this.selectedJob = job;
      this.form.customerId = job.customer_id;
      this.form.bookingFormID = job.id.toString();
      this.form.name = job.customer.firstname + ' ' + job.customer.lastname;
      if (job.vehicle) {
        this.form.reg = job.vehicle.VRVrm || '';
        const vag = ['vw', 'volkswagen', 'volkswagen commercial', 'seat', 'skoda', 'audi'];
        if (vag.includes(job.vehicle.VRMake.toLowerCase())) {
          this.$axios.get('https://darkside-developments.com/vehiclechecklists/common/images/lights/list.php?dir=vw').then((resp) => {
            this.dashlights = resp.data;
          })
        }
        if (job.vehicle.VRMake.toLowerCase() === 'bmw') {
          this.$axios.get('https://darkside-developments.com/vehiclechecklists/common/images/lights/list.php?dir=bmw').then((resp) => {
            this.dashlights = resp.data;
          })
        }
      }
      if (job.pre_work_check) {
        this.form.hasMileage = true;
        this.form.mileageIn = job.pre_work_check.current_mileage
      }
      this.form.notes = job.note || '';
      /*setTimeout(() => {
          this.$refs.notes.setContent(this.form.notes)
      }, 500);*/
    },
    GetStaff() {
      return new Promise((resolve, reject) => {
        this.$axios.get(this.$apiUrl + '/api/users/all').then((resp) => {
          this.staff = resp.data;
          this.staff.push({
            "id": 42,
            "username": "Workshop",
            "nicename": "Workshop"
          })
          this.staff.push({
            "id": 44,
            "username": "Tuning",
            "nicename": "Tuning"
          })
          this.staff.push({
            "id": 99,
            "username": "NA",
            "nicename": "NA"
          })
          this.staff.sort(this.NicenameAlphabetical);
          resolve()
        })
      });
    },
    NicenameAlphabetical(a, b) {
      const A = a.nicename.toUpperCase();
      const B = b.nicename.toUpperCase();

      let comparison = 0;
      if (A > B) {
        comparison = 1;
      } else if (A < B) {
        comparison = -1;
      }
      return comparison;
    },
    GetUsername(id) {
      if (id) {
        const user = this.staff.find(el => el.id === id);
        if (user === undefined) {
          return ''
        }
        return user.nicename;
      } else {
        return '';
      }
    },
    handleScroll() {
      let element = document.getElementById('photos');
      element.scrollIntoView({
        behavior: 'smooth'
      });
    },
    NoteUpdater(name, val, old) {
      if (name.includes('desc')) {
        name = name.replace('_desc', '')
        val = this.form[name];
      }
      const fieldname = name;
      const field = this.warningFields;
      const warn = field[name];
      const title = name.replace(/_/g, ' ');
      name = title.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
      if (Array.isArray(val)) {
        val = this.$_.join(val, ',');
        const checkboxes = document.querySelectorAll('[name="' + fieldname.toLowerCase() + '"]:checked');
        if (checkboxes.length > 0) {
          val = Array.prototype.slice.call(checkboxes).map(x => x.value).join(',');
        }
      }
      if (warn) {
        switch (warn.warnType) {
          case 'interval':
            this.generatedNotes = this.NoteIntervalUpdate(warn, name, val, old, this.generatedNotes, this.form);
            break;
          case 'values':
            this.generatedNotes = this.NoteValuesUpdate(warn, name, val, old, this.generatedNotes, this.form);
            break;
          case 'value':
            this.generatedNotes = this.NoteValueUpdate(warn, name, val, old, this.generatedNotes, this.form);
            break;
          case 'optnotes':
            this.generatedNotes = this.NoteOptNotesUpdate(warn, name, val, old, this.generatedNotes, this.form);
            break;
          case 'optvalues':
            this.generatedNotes = this.NoteOptValuesUpdate(warn, name, val, old, this.generatedNotes, this.form);
            break;
          case 'valmsg':
            this.generatedNotes = this.NoteValMessageUpdate(warn, name, val, old, this.generatedNotes, this.form);
            break;
          case 'notes':
            console.log('has - notes')
            break;
          case 'optnotesfocus':
            console.log('has - optnotesfocus')
            break;
          case 'psi':
            console.log('has - psi')
            break;
          default:
            break;
        }
      }
    },
    AddLightToList(light) {
      let type = null;
      if (light.type === null) {
        type = 'Orange';
      } else if (light.type === 'Orange') {
        type = 'Red';
      } else {
        type = null;
      }
      const indx = this.dashlights.findIndex(el => el.id === light.id);
      if (indx > -1) {
        this.dashlights[indx].type = type;
      }

      const find = this.form.dashLights.findIndex(el => el.name === light.name);
      if (find > -1) {
        if (type === null) {
          this.form.dashLights.splice(find, 1);
        } else {
          this.form.dashLights[find].type = type;
        }
      } else {
        this.form.dashLights.push({
          name: light.name,
          type: type
        });
      }

      this.generatedNotes.dashlights = this.form.dashLights
    },
    GetDashLightClass(light) {
      if (light.type === null) {
        return 'bg-white';
      } else if (light.type === 'Orange') {
        return 'bg-yellow-500';
      } else {
        return 'bg-red-500';
      }
    },
    ConvertNote(str) {
      var splitStr = str.split(' ');
      var first = splitStr[0];
      var join = str.replace(first, '');
      return this.$_.upperFirst(this.$_.lowerCase(first)) + " " + join;
    },
    UpdateNextServiceMiles(e) {
      var val = e.target.value
      if (parseInt(val)) {
        this.form.nextServiceDueDistance = parseInt(val) + 10000;
      } else {
        this.form.nextServiceDueDistance = 10000;
      }
    },
    async Submit() {
      if (!this.$refs.mechSig.signaturePad.isEmpty()) {
        this.form.mechanicSig = this.$refs.mechSig.signaturePad.toDataURL("image/png")
      } else {
        this.form.mechanicSig = null
      }

      if (!this.$refs.mechSig2.signaturePad.isEmpty()) {
        this.form.mechanicSig2 = this.$refs.mechSig2.signaturePad.toDataURL("image/png")
      } else {
        this.form.mechanicSig2 = null
      }

      this.v$.$touch()
      var errors = [];
      for (const [key, value] of Object.entries(this.v$.form)) {
        if (!key.includes("$")) {
          if (value.$error) {
            if (value.$invalid === true) {
              for (let key2 in this.$options.messages) {
                var fieldName = "";
                if (value[key2]) {
                  fieldName = this.$_.startCase(key)
                  if (fieldName === "Photos Exterior Photos") {
                    fieldName = fieldName.replace('Photos Exterior Photos', 'Exterior Photos')
                  }
                  if (fieldName === "Photos Interior Photos") {
                    fieldName = fieldName.replace('Photos Interior Photos', 'Interior Photos')
                  }
                  errors.push({
                    field: fieldName,
                    msg: this.$options.messages[key2](value[key2]) //value[key2].$message
                  })
                }
              }
            }
          }
        }
      }
      if (errors.length > 0) {
        //document.querySelector('ion-content').scrollToTop(500);
        for (let index = 0; index < errors.length; index++) {
          const element = errors[index];
          if (element.field === "Exterior Photos" || element.field === "Interior Photos") {
            if (element.msg === "This field is required") {
              errors.splice(index, 1);
            }
          }
        }
        this.errors = errors
      } else {
        await this.presentLoading();
        //this.form.$state.photos = this.$refs.imageCapture.images;
        const form_data = new FormData();
        for (let key in this.form.$state) {
          /*if(key === "exteriorPhotos" || key === "interiorPhotos" || key === "courtesyCarPhotos") {
              for ( let key2 in this.form.$state[key] ) {
                  for (const key3 in this.form.$state[key][key2]) {
                      form_data.append(key + "[" +key2+"]" + "["+key3+"]", this.form.$state[key][key2][key3]);
                  }
              }
          } else if(key === "mechanicSig") {
              form_data.append("mechanicSignature[0][name]", "MechanicSignature");
              form_data.append("mechanicSignature[0][type]", "Post Work");
              const blobfile = this.dataURLToBlob(this.form.mechanicSig);
              const file = new File([blobfile], 'mechanicSignature.png', {
                  type: "image/png",
                  lastModified: new Date().getTime()
              });
              form_data.append("mechanicSignature[0][image]", file);
          } else if(key === "mechanicSig2") {
              form_data.append("mechanicSignature[1][name]", "MechanicSignature2");
              form_data.append("mechanicSignature[1][type]", "Post Work");
              const blobfile = this.dataURLToBlob(this.form.mechanicSig2);
              const file = new File([blobfile], 'mechanicSignature2.png', {
                  type: "image/png",
                  lastModified: new Date().getTime()
              });
              form_data.append("mechanicSignature[1][image]", file);
          } else {
              form_data.append(key, this.form.$state[key]);
          }*/
          if (key === "mechanicSig") {
            form_data.append("mechanicSignature[0][name]", "MechanicSignature");
            form_data.append("mechanicSignature[0][type]", "Post Work");
            const blobfile = this.dataURLToBlob(this.form.mechanicSig);
            const file = new File([blobfile], 'mechanicSignature.png', {
              type: "image/png",
              lastModified: new Date().getTime()
            });
            form_data.append("mechanicSignature[0][image]", file);
          } else if (key === "mechanicSig2") {
            form_data.append("mechanicSignature[1][name]", "MechanicSignature2");
            form_data.append("mechanicSignature[1][type]", "Post Work");
            const blobfile = this.dataURLToBlob(this.form.mechanicSig2);
            const file = new File([blobfile], 'mechanicSignature2.png', {
              type: "image/png",
              lastModified: new Date().getTime()
            });
            form_data.append("mechanicSignature[1][image]", file);
          } else {
            form_data.append(key, this.form.$state[key]);
          }
        }

        form_data.append("generatedNotes", document.getElementById("generatedNotes").innerText.replaceAll('\n', '<br/>'));
        await this.loading.dismiss()

        this.$axios.post(this.$apiUrl + '/v2/checklist/complete/postwork', form_data).then(async (resp) => {
          console.log(resp.data)
          await this.loading.dismiss()
          await this.presentAlert("Complete", "Form successfully submitted!", true)
          // on success boot the user out, otherwise the wrong user will be
          // tagged on the next postwork submission due to the same device being used
          // for all checklist interactions.
          useMainStore().setIsAuthed(false);
          useMainStore().setAuthUser(null);
          router.back();
        }).catch(async (err) => {
          console.log(err)
          await this.loading.dismiss()
          await this.presentAlert("Error", "Form failed to submit!", false)
        })
      }
    },
    async presentLoading() {
      this.loading = await loadingController.create({
        cssClass: 'my-custom-class',
        message: 'Please wait...'
      });
      await this.loading.present();
    },
    async presentAlert(header, msg, redirect = false) {
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: header,
        message: msg
      });
      await alert.present();

      const {
        role
      } = await alert.onDidDismiss();
      if (redirect) {
        this.$router.push('/checklists')
      }
      console.log('onDidDismiss resolved with role', role);
    },
    exteriorImageTaken(result) {
      const imageCount = this.form.exteriorPhotos.filter(el => el.name === result.name);
      let filename = result.name;
      if (imageCount.length > 0) {
        filename = result.name + " (" + imageCount.length + ")"
      }

      const form_data = new FormData();
      form_data.append("image", result.image);
      form_data.append("image_name", filename);
      form_data.append("checklist_type", 'Post Work');
      form_data.append("job_id", this.selectedJob.id);

      this.$axios.post(this.$apiUrl + '/v2/checklist/upload/image', form_data).then(async (resp) => {
        console.log(resp)
      }).catch(async (err) => {
        console.log(err)
      })
      this.form.exteriorPhotos.push(result)
    },
  },
  messages: {
    required: v => 'This field is required',
    email: v => 'Must be a valid e-mail',
    alphaNum: v => 'May only contain letters and numbers',
    minLength: v => 'Must be at least ' + v.$params.minLength.min + ' characters',
    goodPassword: v => '', //The password rules are always displayed, so no need to show a message
    sameAs: v => 'Must be same as ' + v.$params.sameAs.eq
  }
});
</script>

<style lang="scss" scoped>
div.blocked {
  display: flex;

  > label {
    width: 40%;
  }

  > div {
    width: 60%
  }
}
</style>
