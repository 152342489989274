<template>
    <ion-page>
        <ion-header :translucent="false">
            <ion-toolbar>
                <ion-title class="font-bold">Final Road Test Checklist</ion-title>
                <span slot="end" class="pr-5">
                    <user-chip></user-chip>
                </span>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <ion-header collapse="condense">
                <ion-toolbar>
                    <ion-title size="large">Blank</ion-title>
                </ion-toolbar>
            </ion-header>

            <div id="container">
                <div class="min-h-screen h-full" id="top">
                    <q-card class="">
                        <template #header>
                            <div class="text-2xl font-bold">General</div>
                        </template>
                        <template #main class="q-pt-none">
                            <help-text>Road Test - BEFORE GOING THROUGH GATES, please make sure you use the laptop, follow logging guides for <a target="_blank" href="https://drive.google.com/open?id=0B-RqqvwYVL1FLTBESzF2eGlxUWM">VCDS</a><br><a target="_blank" href="https://drive.google.com/drive/u/0/folders/1d62aAUsGM5nZ-UIHxbeLV83WUqjYPxTZ">Preference Folder</a></help-text>

                            <field-section class="mt-10" title="Registration Number">
                                <q-input v-model="v$.form.reg.$model" :v="v$.form.reg" />
                            </field-section>

                            <field-section class="mt-10" title="Customer Name">
                                <q-input v-model="v$.form.name.$model" :v="v$.form.name" />
                            </field-section>

                            <field-section class="mt-10" title="ECU Type">
                                <button-radio :options="['AMV', 'MB']" v-model="v$.form.ecuType.$model" :v="v$.form.ecuType"></button-radio>
                                <div class="bg-red-400 p-3 mt-1 rounded text-white" v-if="v$.form.ecuType.$model == 'AMV'">
                                    Preference Files for most ECU Types are saved in the Shared Folder<br />
                                    <strong><em>Tuning / Preference Files VCDS</em></strong><br />
                                    Please add any new ones to the folder for future use
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Quick Fault Scan">
                                <button-radio :options="['None Found', 'Investigate', 'Expected Fault']" v-model="v$.form.quickFaultScan.$model" :v="v$.form.quickFaultScan"></button-radio>
                                <div class="bg-red-400 p-3 mt-1 rounded text-white" v-if="v$.form.quickFaultScan.$model == 'None Found'">Save as “Pre-RT Faults“</div>
                                <div class="bg-red-400 p-3 mt-1 rounded text-white" v-if="v$.form.quickFaultScan.$model == 'Investigate' || v$.form.quickFaultScan.$model == 'Expected Fault'">Save as “Pre-RT Faults“ and clear all codes</div>
                            </field-section>

                            <div class="mt-10">
                                <div class="bg-red-400 p-2 text-white rounded">
                                    <p class="font-bold">ALL LOGS WHERE POSSIBLE NEED TO HAVE TURBO AND GROUP UDS SELECTED</p>
                                    <p class="font-bold">ANY ISSUES AT ANY POINT = NO ROAD TEST</p>
                                </div>
                            </div>
                        </template>
                    </q-card>

                    <q-card class="mt-3">
                        <template #header>
                            <div class="text-2xl font-bold">Logs</div>
                        </template>
                        <template #main class="q-pt-none">
                            <div class="bg-gray-100 p-2 rounded">
                                <p class="font-semibold">LOG BELOW ALL TOGETHER FOR 5 SECONDS, REV TO 3000RPM AND WAIT 5 SECONDS OR UNTIL STABLE - NAME “RT STATIC LOG”</p>
                            </div>

                            <field-section class="mt-10" title="Cam Timing">
                                <colour-select :options="[{color:'Red', value:'Red'}, {color:'Orange', value:'Orange'}, {color:'Green', value:'Green'}, {color:'White', value:'White', text: 'N/A'}]" padding v-model="v$.form.camTiming.$model" :v="v$.form.camTiming"></colour-select>
                                <template #after>
                                    <span v-if="v$.form.ecuType.$model == 'AMV'">
                                        TYPE CAM IN SEARCH<br />
                                        SELECT - CAMSHAFT ADAPTATION INTAKE BANK 1: PHASE POSITION<br />
                                        SPECIFICATION +/- 1.00
                                    </span>
                                    <span v-if="v$.form.ecuType.$model == 'MB'">
                                        CH4 (+ CH15 FOR 16V)<br />
                                        SPECIFICATION +/- 1.50 (AND 0.6 FOR 16V)<br />
                                        NO MB FOR CR ENGINES
                                    </span>
                                </template>
                            </field-section>

                            <field-section class="mt-10" title="Coolant Temperature">
                                <template #warning>
                                    At operating temperature -<br />High = 95°C+,<br />OK = 70°C-95°C when warm,<br />LOW = Less than 70°C - Needs Thermostat or Sender
                                </template>
                                <button-radio :options="['High', 'OK', 'Low']" v-model="v$.form.tempSensor.$model" :v="v$.form.tempSensor"></button-radio>
                                <template #after>
                                    <span v-if="v$.form.ecuType.$model == 'AMV'" class="bg-gray-100 rounded w-full p-2 font-semibold">SEARCH COOLANT AND SELECT COOLANT TEMPERATURE</span>
                                    <span v-if="v$.form.ecuType.$model == 'MB'" class="bg-gray-100 rounded w-full p-2 font-semibold">CH1</span>
                                </template>
                            </field-section>

                            <field-section class="mt-10" title="MAF (Mass Air Flow)">
                                <template #warning>Specification - 250mg Idle & 600mg+ Free revving (Standard) - 450-470mg Idle (EGR Deleted) - 400mg @ 2500rpm static limit</template>
                                <button-check :options="['Idle OK', 'Free Revving OK', 'Reads Low']" v-model="v$.form.maf.$model" :v="v$.form.maf"></button-check>
                                <template #after>
                                    <span v-if="v$.form.ecuType.$model == 'AMV'" class="bg-gray-100 rounded w-full p-2 font-semibold">SEARCH AIR AND SELECT AIR MASS SPECIFIED AND AIR MASS ACTUAL - ENSURE BOTH ARE MG/STROKE</span>
                                    <span v-if="v$.form.ecuType.$model == 'MB'" class="bg-gray-100 rounded w-full p-2 font-semibold">CH3</span>
                                </template>
                            </field-section>

                            <field-section class="mt-10" title="Injector Values">
                                <template #warning>
                                    Specification +/- 2.99 - spread of more than 1.5 is yellow, 2.5 is red
                                </template>
                                <colour-select :options="[{color:'Red', value:'Red'}, {color:'Orange', value:'Orange'}, {color:'Green', value:'Green'}]" padding v-model="v$.form.injectorValues.$model" :v="v$.form.injectorValues"></colour-select>
                                <template #after>
                                    <span v-if="v$.form.ecuType.$model == 'AMV'" class="bg-gray-100 rounded w-full p-2 font-semibold">SEARCH INJECTION AND LOCATE INJECTION DEVIATION FOR ALL CYLINDERS</span>
                                    <span v-if="v$.form.ecuType.$model == 'MB'" class="bg-gray-100 rounded w-full p-2 font-semibold">13 18 23 - 5 CYLINDER OR MORE USUALLY ON NEXT CHANNEL SO CAN'T ALL BE LOGGED TOGETHER</span>
                                </template>
                            </field-section>

                            <field-section class="mt-10" title="Pre Turbo EGT">
                                <template #warning>
                                    At Idle - OK = ~120°C, Faulty = Usually 0°C or 950-1000
                                </template>
                                <button-radio :options="['OK', 'Faulty', 'N/A']" v-model="v$.form.preTurboEgt.$model" :v="v$.form.preTurboEgt"></button-radio>
                                <template #after>
                                    <span v-if="v$.form.ecuType.$model == 'AMV'" class="bg-gray-100 rounded w-full p-2 font-semibold">SEARCH EXHAUST AND LOCATE EXHAUST TEMPERATURE 1</span>
                                    <span v-if="v$.form.ecuType.$model == 'MB'" class="bg-gray-100 rounded w-full p-2 font-semibold">CH67 OR 99</span>
                                </template>
                            </field-section>

                            <field-section class="mt-10" title="DPF Back Pressure">
                                <template #warning>
                                    Check DPF Back Pressure at full Load. Should be 4-500mb full load
                                </template>
                                <q-input type="number" v-model="v$.form.dpfBackPressure.$model" :v="v$.form.dpfBackPressure" />
                            </field-section>

                            <field-section class="mt-10" title="DPF Soot Loading">
                                <template #warning>
                                    Check DPF Soot Loading at Idle. 30g is getting high, 40g is unsafe
                                </template>
                                <q-input type="number" v-model="v$.form.dpfSootLoading.$model" :v="v$.form.dpfSootLoading" />
                                <template #after>
                                    <span v-if="v$.form.ecuType.$model == 'AMV'" class="bg-gray-100 rounded w-full p-2 font-semibold">SEARCH FILTER PRESSURE AND SOOT MASS</span>
                                    <span v-if="v$.form.ecuType.$model == 'MB'" class="bg-gray-100 rounded w-full p-2 font-semibold">CH68 OR 100</span>
                                </template>
                            </field-section>

                        </template>
                    </q-card>

                    <q-card class="mt-3">
                        <template #header>
                            <div class="text-2xl font-bold">Dynamic Log</div>
                        </template>
                        <template #main class="q-pt-none">
                            <div class="bg-gray-100 p-2 rounded" v-if="v$.form.ecuType.$model == 'AMV'">
                                <ul>
                                    <li class="font-semibold">SEARCH AND ADD THE BELOW:</li>
                                    <li>CHARGE = CHARGE AIR PRESSURE SPECIFIED VALUE & CHARGE AIR PRESSURE: ACTUAL VALUE & CHARGE PRESSURE ACTUATOR: ACKNOWLEDGEMENT</li>
                                    <li>EXHAUST = EXHAUST GAS TEMPERATURE SENSOR 1</li>
                                    <li>ENGINE = ENGINE SPEED & ENGINE TORQUE</li>
                                    <li>FUEL = HIGH FUEL PRESSURE: SPECIFIED VALUE & FUEL HIGH PRESSURE: ACTUAL VALUE</li>
                                    <li>SAVE SELECTION FILE TO LOGS FOLDER AND DESKTOP</li>
                                </ul>
                            </div>

                            <div class="bg-gray-100 p-2 rounded" v-if="v$.form.ecuType.$model == 'MB'">
                                <ul>
                                    <li>CH11 + 1 FOR EDC15 OR CH11 + 8 + 1 FOR EDC16/17</li>
                                    <li>REPLACE CH8 FOR EGT IF FITTED</li>
                                </ul>
                            </div>

                            <div class="bg-gray-100 p-2 rounded mt-4">
                                <ul>
                                    <li>PREPARE TO LOG (SHOULD DISPLAY “START”)</li>
                                    <li>ROAD TEST - 3RD GEAR AT 1500RPM, START LOG.</li>
                                    <li>FULL THROTTLE TO LIMITER.</li>
                                    <li>SLOW TO 1500 RPM</li>
                                    <li>FULL THROTTLE AND CHANGE TO 4TH GEAR BEFORE REDLINE THEN FULL THROTTLE UNTIL SPEED LIMIT REACHED</li>
                                    <li>STOP LOG IMMEDIATELY AFTER THROTTLE RELEASED</li>
                                </ul>
                            </div>

                            <field-section class="mt-10" title="Log Complete">
                                <template #warning>
                                    Locate and name “Road Test Log”
                                    Usually in C:\Ross-Tech\VCDS\Logs
                                </template>
                                <button-radio :options="['No', 'Yes']" v-model="v$.form.dynamicLogComplete.$model" :v="v$.form.dynamicLogComplete"></button-radio>
                            </field-section>
                        </template>
                    </q-card>

                    <q-card class="mt-3">
                        <template #header>
                            <div class="text-2xl font-bold">Dynamic Log</div>
                        </template>
                        <template #main class="q-pt-none">
                            <field-section class="mt-10" title="Tracking">
                                <template #warning>
                                    Check steering wheel is straight and the vehicle does not pull side to side. Discuss tracking options with customer if there are any issues
                                </template>
                                <colour-select :options="[{color:'Red', value:'Red'}, {color:'Orange', value:'Orange'}, {color:'Green', value:'Green'}]" padding v-model="v$.form.tracking.$model" :v="v$.form.tracking"></colour-select>
                            </field-section>

                            <field-section class="mt-10" title="Cycle All Gears">
                                <colour-select :options="[{color:'Red', value:'Red'}, {color:'Orange', value:'Orange'}, {color:'Green', value:'Green'}]" padding v-model="v$.form.cycleGears.$model" :v="v$.form.cycleGears"></colour-select>
                            </field-section>

                            <field-section class="mt-10" title="Listen for any strange noises">
                                <colour-select :options="[{color:'Red', value:'Red'}, {color:'Orange', value:'Orange'}, {color:'Green', value:'Green'}]" padding v-model="v$.form.strangeNoises.$model" :v="v$.form.strangeNoises"></colour-select>
                            </field-section>

                            <field-section class="mt-10" title="Quick Engine fault scan after test">
                                <template #warning>check for faults after test, if any change from 1st scan save as “faults after RT” - Tick “Investigate” or “none”</template>
                                <button-radio :options="['None Found', 'Investigate', 'Expected Fault']" v-model="v$.form.fullFaultScan.$model" :v="v$.form.fullFaultScan"></button-radio>
                                <template #after>
                                    <span v-if="v$.form.fullFaultScan.$model == 'None Found'" class="bg-gray-100 rounded w-full p-2 font-semibold">SAVE AS “POST-RT FAULTS”</span>
                                    <span v-if="v$.form.fullFaultScan.$model == 'Investigate'" class="bg-gray-100 rounded w-full p-2 font-semibold">SAVE AS “POST-RT FAULTS” AND CLEAR ALL CODES</span>
                                    <span v-if="v$.form.fullFaultScan.$model == 'Expected Fault'" class="bg-gray-100 rounded w-full p-2 font-semibold">SAVE AS “POST-RT FAULTS” AND CLEAR ALL CODES</span>
                                </template>
                            </field-section>

                            <field-section class="mt-10" title="Clear service light if done">
                                <button-radio :options="['Cleared - Service', 'Cleared - Turbo', 'NA']" v-model="v$.form.clearLight.$model" :v="v$.form.clearLight"></button-radio>
                            </field-section>

                            <field-section class="mt-10" title="Analyse Road test logs">
                                <colour-select :options="[{color:'Red', value:'Red'}, {color:'Orange', value:'Orange'}, {color:'Green', value:'Green'}]" padding v-model="v$.form.analyseLogs.$model" :v="v$.form.analyseLogs"></colour-select>
                            </field-section>

                            <div class="bg-gray-100 p-2 rounded mt-10">
                                MOVE ALL FILES TO CUSTOMERS FOLDER
                            </div>

                            <field-section class="mt-10" title="Keys in Safe">
                                <button-radio :options="['Yes', 'No']" v-model="v$.form.keysInSafe.$model" :v="v$.form.keysInSafe"></button-radio>
                            </field-section>

                            <div class="bg-gray-100 p-2 rounded mt-10">
                                ANY ISSUES NEED TO BE FED BACK TO NOTES SECTION / JOB COST SHEET IMMEDIATELY.
                            </div>

                            <field-section class="mt-10" title="Checklist Notes">
                                <div class="border border-color p-2 rounded">
                                    <ul id="generatedNotes">
                                        <li v-for="(note,index) in generatedNotes" :key="index">{{ConvertNote(note)}}</li>
                                    </ul>
                                </div>
                            </field-section>

                            <field-section class="mt-10" title="Notes">
                                <q-editor v-model="v$.form.notes.$model" :v="v$.form.notes" min-height="5rem" />
                            </field-section>

                            <div class="bg-gray-100 p-2 rounded mt-10">
                                SPEAK TO CUSTOMER TO CONFIRM ROAD TEST IS OK, OR DISCUSS ISSUES AND POTENTIAL SOLUTIONS
                            </div>

                            <div class="bg-gray-100 p-2 rounded mt-4">
                                SAVE ROAD TEST LOGS IN TO S:DRIVE FOLDER, SEARCH REGISTRATION TO FIND THE CLIENT/CUSTOMER. IF THE CLIENT/CUSTOMER IS A RETURNING CLIENT/CUSTOMER USE VERSION CONTROL TO HIGHLIGHT THE MOST RECENT FILE (AB12 CDE - JOHN SMITH (1)). ONCE THE VEHICLE IS PARKED IN THE YARD ENSURE THE JOB SHEET IS CREATED AND MOVED TO THE “WORKSHOP URGENT” TASK LIST.
                            </div>
                        </template>
                    </q-card>

                    <div class="pt-3 pb-3 px-2 w-full">
                        <q-btn expand="block" @click="Submit">SUBMIT</q-btn>
                    </div>

                    <q-card v-if="errors.length > 0">
                        <template #header>
                            <div class="text-2xl font-bold">Errors</div>
                        </template>
                        <template #main class="q-pt-none">
                            <div>
                                <ul>
                                    <li v-for="(err, index) in errors" :key="index">
                                        <span class="inline-block mb-2 font-bold text-red-500">{{err.field}} - </span> <span class="inline-block w-32">{{err.msg}}</span>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </q-card>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    loadingController,
    alertController
} from '@ionic/vue';
import {
    defineComponent
} from 'vue';
import useVuelidate from '@vuelidate/core'
import {
    required,
    requiredIf,
    email,
    minValue,
    numeric
} from '@vuelidate/validators'
import {useMainStore} from "@/store/mainStore";
import {useFinalRoadTestStore} from "@/store/finalRoadTestStore";
export default defineComponent({
    name: 'Home',
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            layout: "blocked",
            photoType: null,
            currentImages: [],
            loading: false,
            cameraOpen: false,
            jobs: [],
            jobIdOptions: [],
            selectedJob: null,
            jobLoaded: false,
            operators: [],
            staff: [],
            generatedNotes: [],
            jobslist: [],
            dashlights: [],
            form: useFinalRoadTestStore(),
            errors: []
        }
    },
    validations() {
        return {
            form: {
                reg: {
                    required
                },
                name: {
                    required
                },
                ecuType: {
                    required
                },
                quickFaultScan: {
                    required
                },
                camTiming: {
                    required
                },
                tempSensor: {
                    required
                },
                injectorValues: {
                    required
                },
                maf: {
                    required
                },
                mafIdle: {
                    required
                },
                mafRevving: {
                    required
                },
                mafLow: {
                    required
                },
                preTurboEgt: {
                    required
                },
                dpfBackPressure: {
                    required
                },
                dpfSootLoading: {
                    required
                },
                dynamicLogComplete: {
                    required
                },
                tracking: {
                    required
                },
                cycleGears: {
                    required
                },
                strangeNoises: {
                    required
                },
                fullFaultScan: {
                    required
                },
                checkCompletedBy: {
                    required
                },
                clearLight: {
                    required
                },
                analyseLogs: {
                    required
                },
                keysInSafe: {
                    required
                },
                bookingFormID: {
                    required
                },
                notes: {
                    required
                }
            }
        }
    },
    async mounted() {
        await this.presentLoading();
        this.form.checkCompletedBy = useMainStore().authUser.value
        this.form.jobId = this.$router.currentRoute.value.params.id
        this.GetStaff().then(() => {
            this.GetJobById(this.$router.currentRoute.value.params.id)
        })
        for (const k in this.form) {
            this.$watch('form.' + k, (val, oldVal) => {
                this.NoteUpdater(k, val, oldVal);
            })
        }
    },
    methods: {
        UpdateLayout(a) {
            var val = a.detail.checked;
            if (val) {
                this.layout = "condensed";
            } else {
                this.layout = "blocked";
            }
        },
        GetJobById(id) {
            const jobid = id;
            this.$axios.get(this.$apiUrl + '/v2/checklist/job/' + jobid).then(async (resp) => {
                await this.$axios.get(this.$apiUrl + '/v2/checklist/job/folder/' + jobid).then(response => {
                    if (response.data && response.data.success) {
                        this.form.folderId = response.data.message;
                    } else {
                        this.form.folderId = this.$checklistTempFolder;//'0B-RqqvwYVL1FYnA5VktBWjhiQlU';
                    }
                })
                this.Populate(resp.data);
                await this.loading.dismiss()
                this.loading = false;
            }).catch(async () => {
                await this.loading.dismiss()
                this.loading = false;
            })
        },
        Populate(job) {
            if (job.folder_id === null || job.folder_id === '') {
                alert('NO FOLDER FOR JOB');
            }
            this.jobLoaded = true;
            this.selectedJob = job;
            this.form.customerId = job.customer_id;
            this.form.bookingFormID = job.id.toString();
            this.form.name = job.customer.firstname + ' ' + job.customer.lastname;
            if (job.vehicle) {
                this.form.reg = job.vehicle.VRVrm || '';
            }
            this.form.notes = job.note || '';
            /*setTimeout(() => {
                this.$refs.notes.setContent(this.form.notes)
            }, 500);*/
        },
        GetStaff() {
            return new Promise((resolve, reject) => {
                this.$axios.get(this.$apiUrl + '/api/users/all').then((resp) => {
                    this.staff = resp.data;
                    this.staff.push({
                        "id": 42,
                        "username": "Workshop",
                        "nicename": "Workshop"
                    })
                    this.staff.push({
                        "id": 44,
                        "username": "Tuning",
                        "nicename": "Tuning"
                    })
                    this.staff.push({
                        "id": 99,
                        "username": "NA",
                        "nicename": "NA"
                    })
                    this.staff.sort(this.NicenameAlphabetical);
                    resolve()
                })
            });
        },
        NicenameAlphabetical(a, b) {
            const A = a.nicename.toUpperCase();
            const B = b.nicename.toUpperCase();

            let comparison = 0;
            if (A > B) {
                comparison = 1;
            } else if (A < B) {
                comparison = -1;
            }
            return comparison;
        },
        GetUsername(id) {
            if (id) {
                const user = this.staff.find(el => el.id === id);
                if (user === undefined) {
                    return ''
                }
                return user.nicename;
            } else {
                return '';
            }
        },
        NoteUpdater(name, val, old) {
            if (name.includes('desc')) {
                name = name.replace('_desc', '')
                val = this.form[name];
            }
            const fieldname = name;
            const field = this.warningFields;
            const warn = field[name];
            const title = name.replace(/_/g, ' ');
            name = title.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            if (Array.isArray(val)) {
                val.sort()
                val = this.$_.join(val, ',');
                const checkboxes = document.querySelectorAll('[name="' + fieldname.toLowerCase() + '"]:checked');
                if (checkboxes.length > 0) {
                    val = Array.prototype.slice.call(checkboxes).map(x => x.value).join(',');
                }
            }
            if (warn) {
                switch (warn.warnType) {
                    case 'interval':
                        this.generatedNotes = this.NoteIntervalUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'values':
                        this.generatedNotes = this.NoteValuesUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'value':
                        this.generatedNotes = this.NoteValueUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'optnotes':
                        this.generatedNotes = this.NoteOptNotesUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'optvalues':
                        this.generatedNotes = this.NoteOptValuesUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'valmsg':
                        this.generatedNotes = this.NoteValMessageUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'notes':
                        console.log('has - notes')
                        break;
                    case 'optnotesfocus':
                        console.log('has - optnotesfocus')
                        break;
                    case 'psi':
                        console.log('has - psi')
                        break;
                    default:
                        break;
                }
            }
        },
        ConvertNote(str) {
            var splitStr = str.split(' ');
            var first = splitStr[0];
            var join = str.replace(first, '');
            return this.$_.upperFirst(this.$_.lowerCase(first)) + " " + join;
        },
        async Submit() {
            this.v$.$touch()
            var errors = [];
            for (const [key, value] of Object.entries(this.v$.form)) {
                if (!key.includes("$")) {
                    if (value.$error) {
                        if (value.$invalid === true) {
                            for (let key2 in this.$options.messages) {
                                if (value[key2]) {
                                    var fieldName = this.$_.startCase(key)
                                    errors.push({
                                        field: fieldName,
                                        msg: value[key2].$message
                                    })
                                }
                            }
                        }
                    }
                }
            }
            if (errors.length > 0) {
                document.querySelector('ion-content').scrollToTop(500);
                this.errors = errors
            } else {
                await this.presentLoading();
                this.form.$state.generatedNotes = document.getElementById("generatedNotes").innerText.replaceAll('\n', '<br/>');
                this.$axios.post(this.$apiUrl + '/v2/checklist/complete/finalroadtest', this.form.$state).then(async (resp) => {
                    await this.loading.dismiss()
                    this.loading = false
                    this.presentAlert("Complete", "Form successfully submitted!", true)
                }).catch(async (err) => {
                    console.log(err)
                    await this.loading.dismiss()
                    this.loading = false
                    this.presentAlert("Error", err, false)
                })
            }
        },
        async presentLoading() {
            this.loading = await loadingController.create({
                cssClass: 'my-custom-class',
                message: 'Please wait...'
            });
            await this.loading.present();
        },
        async presentAlert(header, msg, redirect = false) {
            const alert = await alertController.create({
                cssClass: 'my-custom-class',
                header: header,
                message: msg
            });
            await alert.present();

            const {
                role
            } = await alert.onDidDismiss();
            if (redirect) {
                this.$router.push('/checklists')
            }
            console.log('onDidDismiss resolved with role', role);
        },
    },
    messages: {
        required: v => 'This field is required',
        email: v => 'Must be a valid e-mail',
        alphaNum: v => 'May only contain letters and numbers',
        minLength: v => 'Must be at least ' + v.$params.minLength.min + ' characters',
        goodPassword: v => '', //The password rules are always displayed, so no need to show a message
        sameAs: v => 'Must be same as ' + v.$params.sameAs.eq
    }
});
</script>

<style lang="scss" scoped>
div.blocked {
    display: flex;

    >label {
        width: 40%;
    }

    >div {
        width: 60%
    }
}
</style>
