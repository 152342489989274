<template>
    <div class="bg-red-500 p-2 text-white rounded font-bold mt-2 flex items-center">
        <q-icon icon="warning" class="mr-2 text-white"></q-icon>
        <slot></slot>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
</style>
