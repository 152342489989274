import {defineStore} from "pinia";

const initialState = {
    reg: '',
    name: '',
    ecuType: '',
    quickFaultScan: '',
    camTiming: '',
    tempSensor: '',
    maf: [],
    mafIdle: false,
    mafRevving: false,
    mafLow: false,
    injectorValues: '',
    preTurboEgt: '',
    dpfBackPressure: '',
    dpfSootLoading: '',
    dynamicLogComplete: 'No',
    tracking: '',
    cycleGears: '',
    strangeNoises: '',
    fullFaultScan: '',
    checkLastServiceTime: '',
    checkLastServiceDistance: '',
    clearLight: '',
    analyseLogs: '',
    keysInSafe: '',
    checkCompletedBy: '',
    folderId: '',
    bookingFormID: '',
    notes: '',
    // Desc fields (custom notes)
    quickFaultScanDesc: '',
    camTimingDesc: '',
    tempSensorDesc: '',
    injectorValuesDesc: '',
    mafDesc: '',
    preTurboEgtDesc: '',
    trackingDesc: '',
    cycleGearsDesc: '',
    strangeNoisesDesc: '',
    fullFaultScanDesc: '',
    clearLightDesc: '',
    analyseLogsDesc: '',
}
export const useRoadTestStore = defineStore('roadTestStore',{
        state: () => ({ ...initialState }),
    actions: {
        async clearAll() {
            localStorage.removeItem('roadTestStore')
            Object.assign(this, {...initialState})
        }
    },
    persist: false,
});
