<template>
    <div class="bg-gray-100 p-2 rounded mt-2 flex items-center">
        <q-icon icon="info" class="mr-2 text-gray-500"></q-icon>
        <slot></slot>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
</style>
