<template>
    <ion-page>
        <!-- Header Section -->
        <ion-header :translucent="false">
            <ion-toolbar>
                <!-- Back Button -->
                <ion-buttons slot="start">
                    <ion-back-button></ion-back-button>
                </ion-buttons>
                <!-- Page Title -->
                <ion-title class="font-bold">Meet &amp; Greet Checklist</ion-title>
                <!-- User Chip Component (e.g., user avatar or info) -->
                <span slot="end" class="pr-5">
                    <user-chip></user-chip>
                </span>
            </ion-toolbar>
        </ion-header>

        <!-- Main Content -->
        <ion-content :fullscreen="true">

            <!-- Container for the form, conditionally rendered if jobId exists -->
            <div id="container" v-if="form.jobId">
                <!-- Camera Component (Conditional Rendering) -->
                <camera v-if="cameraOpen" @cameraClosed="CameraClosed" :type="photoType" :current-images="currentImages" :folder-id="form.folderId" check-type="Pre Work" :jobid="form.jobId" :required-images="requiredImages"></camera>

                <!-- Form (Conditional Rendering, shown when camera is closed) -->
                <div v-show="!cameraOpen" class="min-h-screen h-full" id="top">
                    <!-- General Information Card -->
                    <q-card aria-label="pdf-page-1" class="">
                        <template #header>
                            <div class="text-2xl font-bold">General</div>
                        </template>
                        <template #main class="q-pt-none">
                            <!-- Help Text for General Section -->
                            <help-text>Upon arrival meet and greet the client/customer with an appropriate welcome, ensure that you set the client/customer expectations around work to be undertaken. Once the client/customer meet and greet has been completed start the “Pre Work Checklist”, using the tablet.</help-text>

                            <!-- Registration Number Field -->
                            <field-section class="mt-4" title="Registration Number">
                                <q-input v-model="v$.form.reg.$model" :v="v$.form.reg" />
                            </field-section>

                            <!-- Customer Name Field -->
                            <field-section class="mt-10" title="Customer Name">
                                <q-input v-model="v$.form.name.$model" :v="v$.form.name" />
                            </field-section>

                            <!-- Customer Email Field -->
                            <field-section class="mt-10" title="Customer Email">
                                <q-input v-model="v$.form.email.$model" :v="v$.form.email" />
                            </field-section>

                            <!-- Customer Contact Number Field -->
                            <field-section class="mt-10" title="Customer Contact Number">
                                <q-input v-model="v$.form.contactNum.$model" :v="v$.form.contactNum" />
                            </field-section>

                            <!-- Preferred Contact Method Field -->
                            <field-section class="mt-10" title="Preferred Contact Method">
                                <button-radio :options="['Phone', 'Email']" v-model="v$.form.contactMethod.$model" :v="v$.form.contactMethod"></button-radio>
                            </field-section>

                            <!-- Customer Location Field -->
                            <field-section class="mt-10" title="Customer Location">
                                <button-radio :options="customerLocations" v-model="v$.form.customerLocation.$model" :v="v$.form.customerLocation"></button-radio>
                            </field-section>

                            <!-- Customer Location Confirmation Field -->
                            <field-section class="mt-10" title="Customer Location Confirmed">
                                <button-radio :options="['Yes', 'No']" v-model="v$.form.customerLocationConfirmed.$model" :v="v$.form.customerLocationConfirmed"></button-radio>
                            </field-section>

                            <!-- Old Parts Field -->
                            <field-section class="mt-10" title="Old Parts">
                                <button-radio :options="['Customer to Keep', 'Scrap', 'Sell to SRS', 'Exchange Parts', 'NA']" v-model="v$.form.oldParts.$model" :v="v$.form.oldParts"></button-radio>
                            </field-section>

                            <!-- Locking Wheel Nut Key Location Field -->
                            <field-section class="mt-10" title="Locking Wheel Nut Key Location">
                                <div>
                                    <button-radio :options="['Spare Wheel Well', 'Glovebox', 'Centre Console', 'Ashtray', 'Other', 'MISSING', 'NA']" v-model="v$.form.lockingWheelNutLocation.$model" :v="v$.form.lockingWheelNutLocation"></button-radio>
                                    <!-- Warning if Locking Wheel Nut Key is Missing -->
                                    <div class="bg-red-400 p-3 mt-1 rounded text-white" v-if="v$.form.lockingWheelNutLocation.$model === 'MISSING'">
                                        We may need to Burn, Smash or Drill the Locking Wheel Bolts to remove them. This may cause damage to your wheels, and you will need to purchase a new set of Locking Wheel Bolts / Nuts
                                    </div>
                                    <!-- Additional Input for Other Location -->
                                    <q-input class="mt-2" placeholder="Locking Wheel Nut Key Location" v-model="v$.form.lockingWheelNutLocationOther.$model" v-if="v$.form.lockingWheelNutLocation.$model == 'Other'" :v="v$.form.lockingWheelNutLocationOther" />
                                </div>
                            </field-section>

                            <!-- Dashcam Fitted Field -->
                            <field-section class="mt-10" title="Is a Dashcam Fitted? (Switched Off and Memory Card Removed)">
                                <button-radio :options="['Yes', 'No']" v-model="v$.form.dashcamFitted.$model" :v="v$.form.dashcamFitted"></button-radio>
                            </field-section>

                            <!-- Black Box Fitted Field -->
                            <field-section class="mt-10" title="Is a Black Box Fitted?">
                                <div>
                                    <button-radio :options="['Yes', 'No']" v-model="v$.form.hasBlackbox.$model" :v="v$.form.hasBlackbox"></button-radio>
                                    <!-- Warning if Black Box is Fitted -->
                                    <div class="bg-red-400 p-3 mt-1 rounded text-white" v-if="v$.form.hasBlackbox.$model == 'Yes'">
                                        TELL CUSTOMER THEY NEED TO RING THEIR INSURANCE COMPANY TO LET THEM KNOW THAT THEIR VEHICLE WILL BE IN THE WORKSHOP FOR X DAYS.
                                    </div>
                                </div>
                            </field-section>

                            <!-- Emissions Work Field -->
                            <field-section class="mt-10" title="Has Emissions Work?">
                                <button-radio :options="['Yes', 'No']" v-model="v$.form.hasEmissionWork.$model" :v="v$.form.hasEmissionWork" @click="isResponsibilityRequired"></button-radio>
                            </field-section>

                            <!-- Ghost Alarm Field -->
                            <field-section class="mt-10" title="Has Ghost Alarm">
                                <div>
                                    <button-radio :options="['Yes', 'No']" v-model="v$.form.hasGhostAlarm.$model" :v="v$.form.hasGhostAlarm"></button-radio>
                                    <!-- Additional Input for Ghost Alarm Code -->
                                    <q-input class="mt-2" placeholder="Ghost alarm code..." v-model="v$.form.hasGhostAlarmCode.$model" v-if="v$.form.hasGhostAlarm.$model == 'Yes'" :v="v$.form.hasGhostAlarmCode" />
                                </div>
                            </field-section>

                            <!-- Job Owner Field (Read-only) -->
                            <field-section class="mt-10" title="Job Owner">
                                <q-input v-model="v$.form.jobOwner.$model" readonly />
                            </field-section>

                            <!-- Tuning Owner Field (Read-only) -->
                            <field-section class="mt-10" title="Tuning Owner">
                                <q-input v-model="v$.form.tuningOwner.$model" readonly />
                            </field-section>
                        </template>
                    </q-card>

                    <!-- Costs Card -->
                    <q-card aria-label="pdf-page-2" class="mt-3">
                        <template #header>
                            <div class="flex items-center">
                                <div class="text-2xl font-bold">Costs</div>
                                <!-- Button to View Full Parts List -->
                                <q-btn color="primary" class="ml-auto" size="small">Full parts list to be fitted & Costs</q-btn>
                            </div>
                        </template>

                        <template #main class="q-pt-none">
                            <!-- Parts List Confirmation Field -->
                            <field-section class="mt-4" title="Full parts list to be fitted - Confirmed?">
                                <button-radio :options="['Yes', 'No']" v-model="v$.form.partsConfirmed.$model" :v="v$.form.partsConfirmed"></button-radio>
                            </field-section>

                            <!-- Authorised Extra Costs Field -->
                            <field-section class="mt-10" title="Authorised Extra Costs (above what is already confirmed) before phonecall / email required">
                                <q-input prefix="£" type="number" v-model="v$.form.authorisedCosts.$model" :v="v$.form.authorisedCosts" />
                            </field-section>

                            <!-- Deposit Paid Field (Read-only) -->
                            <field-section class="mt-10" title="Deposit Paid">
                                <q-input prefix="£" type="number" v-model="v$.form.deposit.$model" class="readonly" />
                            </field-section>
                        </template>
                    </q-card>

                    <!-- Tuning Card (Conditional Rendering if Tuning Job Exists) -->
                    <q-card aria-label="pdf-page-3" class="mt-3" v-if="selectedJob && selectedJob.tuning">
                        <template #header>
                            <div class="flex items-center">
                                <div class="text-2xl font-bold">Tuning</div>
                                <!-- Button to View Tuning Job Details -->
                                <q-btn color="primary" class="ml-auto" size="small">Tuning Job Details</q-btn>
                            </div>
                        </template>

                        <template #main class="q-pt-none">
                            <!-- Tuning Job Details Confirmation Field -->
                            <field-section class="mt-4" title="Tuning Job Details - Confirmed?">
                                <button-radio :options="['Yes', 'No']" v-model="v$.form.tuningConfirmed.$model" :v="v$.form.tuningConfirmed"></button-radio>
                            </field-section>

                            <!-- Expected Power Field -->
                            <field-section class="mt-10" title="Expected Power (BHP)">
                                <q-input type="number" v-model="v$.form.expectedPower.$model" :v="v$.form.expectedPower" />
                            </field-section>

                            <!-- Smoke Level Field -->
                            <field-section class="mt-10" title="Smoke Level">
                                <button-radio :options="['Smoke Free', 'Light Haze', 'Mid-Smoke', 'Heavy Smoke', 'No Preference']" v-model="v$.form.smokeLevel.$model" :v="v$.form.smokeLevel"></button-radio>
                            </field-section>

                            <!-- Hardcut Limiter Field -->
                            <field-section class="mt-10" title="Hardcut Limiter">
                                <div>
                                    <button-radio :options="['Yes', 'No']" v-model="v$.form.hardcut.$model" :v="v$.form.hardcut"></button-radio>
                                    <!-- Additional Input for Hardcut RPM -->
                                    <q-input class="mt-2" v-model="v$.form.hardcutRpm.$model" placeholder="RPM..." v-if="v$.form.hardcut.$model == 'Yes'" />
                                </div>
                            </field-section>

                            <!-- Launch Control Field -->
                            <field-section class="mt-10" title="Launch Control">
                                <div>
                                    <button-radio :options="['Yes', 'No']" v-model="v$.form.launch.$model" :v="v$.form.launch"></button-radio>
                                    <!-- Additional Input for Launch RPM -->
                                    <q-input class="mt-2" v-model="v$.form.launchRpm.$model" placeholder="RPM..." v-if="v$.form.launch.$model == 'Yes'" />
                                </div>
                            </field-section>
                        </template>
                    </q-card>

                    <!-- Exterior Checks Card -->
                    <q-card aria-label="pdf-page-4" class="mt-3" id="ext_checks">
                        <template #header>
                            <div class="text-2xl font-bold">Exterior Checks</div>
                        </template>

                        <template #main class="q-pt-none">
                            <!-- Photo Capture Component for Exterior Photos -->
                            <PhotoCapture v-for="img in externalPhotos" :key="img.id" :img="img" @image-taken="exteriorImageTaken" type="Pre Work" :jobid="form.jobId" :photos="form.exteriorPhotos"></PhotoCapture>

                            <!-- General Condition of Tyres Field -->
                            <field-section class="mt-10" title="General Condition of Tyres">
                                <div>
                                    <!-- Colour Select for Tyre Condition -->
                                    <colour-select v-model="v$.form.generalConditionOfTyres.$model" padding />
                                    <!-- Additional Input for Tyre Notes -->
                                    <q-input v-model="v$.form.generalConditionOfTyresDesc.$model" class="mt-2" :v="v$.form.generalConditionOfTyres" placeholder="Tyre notes..." />

                                    <!-- Additional Fields for Tyre Size and Brand (Conditional Rendering) -->
                                    <div v-if="v$.form.generalConditionOfTyres.$model == 'Red' || v$.form.generalConditionOfTyres.$model == 'Orange'" class="mt-2 w-full">
                                        <label class="font-semibold text-lg">Tyre Size</label>
                                        <div class="flex items-center gap-2">
                                            <q-select :options="tyreDepths" v-model="v$.form.tyreWidth.$model" :v="v$.form.tyreWidth"></q-select>
                                            /
                                            <q-select :options="tyreWidths" v-model="v$.form.tyreDepth.$model" :v="v$.form.tyreDepth"></q-select>
                                            /
                                            <q-select :options="tyreSizes" v-model="v$.form.tyreDiameter.$model" :v="v$.form.tyreDiameter" prefix="R"></q-select>
                                        </div>
                                        <label class="font-semibold text-lg mt-2 block">Tyre Brand & Model</label>
                                        <q-input v-model="v$.form.tyreBrand.$model" :v="v$.form.tyreBrand" />
                                    </div>
                                </div>
                            </field-section>
                        </template>
                    </q-card>

                    <!-- Interior Checks Card -->
                    <q-card aria-label="pdf-page-5" class="mt-3">
                        <template #header>
                            <div class="text-2xl font-bold">Interior Checks</div>
                        </template>

                        <template #main class="q-pt-none">
                            <!-- Photo Capture Component for Interior Photos -->
                            <PhotoCapture v-for="img in interiorPhotos" :key="img.id" :img="img" @image-taken="interiorImageTaken" type="Pre Work" :jobid="form.jobId" :photos="form.interiorPhotos"></PhotoCapture>

                            <!-- Lights on Dashboard Check Field -->
                            <field-section class="mt-10" title="Lights on Dashboard Check">
                                <div>
                                    <!-- Colour Select for Dashboard Lights -->
                                    <colour-select :options="[{color:'Green', value:'No'},{color:'Red', value:'Yes'}]" v-model="v$.form.lightsOnDashboardCheck.$model" padding :v="v$.form.lightsOnDashboardCheck" />
                                    <!-- Dashboard Lights Icons (Conditional Rendering) -->
                                    <div v-if="v$.form.lightsOnDashboardCheck.$model == 'Yes'" class="flex flex-wrap gap-3 mt-2 justify-center">
                                        <div class="cursor-pointer text-center w-1/5 border border-gray-400 p-2 transition ease-in-out duration-300" v-for="light in dashlights" :key="light.id" @click="AddLightToList(light)" :class="GetDashLightClass(light)">
                                            <img class="mx-auto" :src="light.path">
                                            <p class="select-none text-lg">{{light.name}}</p>
                                        </div>
                                    </div>
                                </div>
                            </field-section>

                            <!-- Service Indicator Present Field -->
                            <field-section class="mt-10" title="Service Indicator Present">
                                <button-radio :options="['Yes', 'No']" v-model="v$.form.serviceIndicatorPresent.$model" :v="v$.form.serviceIndicatorPresent"></button-radio>
                            </field-section>

                            <!-- Current Mileage Field -->
                            <field-section class="mt-10" title="Current Mileage">
                                <q-input type="number" v-model="v$.form.currentMileage.$model" :v="v$.form.currentMileage" />
                            </field-section>

                            <!-- Turbo Noise Field -->
                            <field-section class="mt-10" title="Turbo Noise">
                                <colour-select :options="[{color:'Green', value:'Green'},{color:'Yellow', value:'Yellow'},{color:'Orange', value:'Orange'},{color:'Red', value:'Red'}]" v-model="v$.form.turboNoise.$model" :v="v$.form.turboNoise" padding />
                            </field-section>
                        </template>
                    </q-card>

                    <!-- Courtesy Car Card -->
                    <q-card aria-label="pdf-page-6" class="mt-3">
                        <template #header>
                            <div class="text-2xl font-bold">Courtesy Car</div>
                        </template>

                        <template #main class="q-pt-none">
                            <!-- Has Courtesy Car Field -->
                            <field-section title="Has Courtesy Car?">
                                <button-radio :options="['Yes', 'No']" v-model="v$.form.hasCourtesyCar.$model" :v="v$.form.hasCourtesyCar"></button-radio>
                            </field-section>

                            <!-- Photo Capture Component for Courtesy Car Photos (Conditional Rendering) -->
                            <template v-if="v$.form.hasCourtesyCar.$model === 'Yes'">
                                <PhotoCapture v-for="img in courtesyCarPhotos" :key="img.id" :img="img" @image-taken="courteseyCarImageTaken" type="Pre Work" :jobid="form.jobId" :photos="form.courtesyCarPhotos"></PhotoCapture>
                            </template>
                        </template>
                    </q-card>

                    <!-- Others Card -->
                    <q-card aria-label="pdf-page-7" class="mt-3">
                        <template #header>
                            <div class="text-2xl font-bold">Others</div>
                        </template>

                        <template #main class="q-pt-none">
                            <!-- Customer Present During Check Field -->
                            <field-section class="mt-4" title="Customer Present During Check">
                                <div>
                                    <button-radio :options="['Present', 'Not Present', '3rd Party']" v-model="v$.form.customerPresent.$model" :v="v$.form.customerPresent"></button-radio>
                                    <!-- Warning if Customer is Not Present -->
                                    <div class="bg-red-400 p-3 mt-1 rounded text-white" v-if="v$.form.customerPresent.$model === 'Not Present'">
                                        I CONFIRM THAT I AM HAPPY TO LEAVE MY CAR WITH DARKSIDE DEVELOPMENTS WITHOUT WITNESSING THE VEHICLE CHECK
                                    </div>
                                    <!-- Warning if Customer is 3rd Party -->
                                    <div class="bg-red-400 p-3 mt-1 rounded text-white" v-if="v$.form.customerPresent.$model === '3rd Party'">
                                        3RD PARTY LEFT VEHICLE FOR COLLECTION, CUSTOMER NOT PRESENT DURING CHECKS
                                    </div>
                                </div>
                            </field-section>

                            <!-- Checklist Notes Field -->
                            <field-section class="mt-10" title="Checklist Notes">
                                <div class="border border-color p-2 rounded checklist-notes">
                                    <ul id="generatedNotes">
                                        <!-- Dynamically Generated Notes -->
                                        <li v-for="(note,index) in generatedNotes" :key="index">{{ConvertNote(note)}}</li>
                                        <li v-for="(note,index) in generatedNotes.dashlights" :key="index">Lights on Dashboard - {{note.name}} - {{note.type}}</li>
                                    </ul>
                                </div>
                            </field-section>

                            <!-- Additional Notes Field -->
                            <field-section class="mt-10" title="Notes">
                                <q-editor v-model="v$.form.notes.$model" :v="v$.form.notes" min-height="5rem" />
                            </field-section>
                        </template>
                    </q-card>

                    <!-- Customer Card -->
                    <q-card aria-label="pdf-page-8" class="mt-3">
                        <template #header>
                            <div class="text-2xl font-bold">Customer</div>
                        </template>

                        <template #main class="q-pt-none">
                            <!-- Help Text for Customer Section -->
                            <help-text class="mt-5">
                                Secure the visual sign off by the client/customer once all photos and data capture is complete.
                            </help-text>

                            <!-- Terms and Conditions Confirmation -->
                            <div class="mt-5">
                                <p class="font-bold">I, THE CUSTOMER, CONFIRM ALL THE INFORMATION IN THIS FORM IS CORRECT AND AGREE TO THE <a class="text-blue-500" href="http://www.darksidedevelopments.co.uk/terms-and-conditions#Vehicle_drop_off_Workshop_Tuning" target="_blank">TERMS AND CONDITIONS</a> THAT WERE EMAILED TO ME AT THE TIME OF BOOKING</p>
                            </div>

                            <!-- Customer Signature Section (Conditional Rendering) -->
                            <div class="mt-5" v-if="isResponsibilityAccepted">
                                <label class="font-semibold text-lg">Customer Signature</label>
                                <sigpad ref="sigpad" @signature-saved="SaveSignature"></sigpad>
                            </div>
                            <div class="mt-5" v-else>
                                <field-section class="text-red-600 font-bold" title="I, the customer, accept that removal
                              or modification to the factory emissions equipment is solely for motorsport / off road use
                              only. I also acknowledge that once this work is carried out, the vehicle can no longer legally
                              be driven on the public highway.">
                                    <button-radio :options="['Accept','Decline']" v-model="v$.form.responsibilityConfirmed.$model" :v="v$.form.responsibilityConfirmed" @click="Accepted"></button-radio>
                                </field-section>
                            </div>

                            <!-- Information Disclaimer -->
                            <div class="mt-5">
                                <p class="font-bold">INFORMATION IS FOR DARKSIDE DEVELOPMENTS USE ONLY AND WILL NOT BE SHARED WITH ANY OTHER COMPANIES</p>
                            </div>

                            <!-- Help Text for Customer Section -->
                            <help-text class="mt-5">
                                Advise the client/customer to please take a seat in reception whilst we undertake the vehicle road test, allocate road test to the appropriate member of the team. (Tuning or Service Workshop).
                            </help-text>
                        </template>
                    </q-card>

                    <!-- Submit Button -->
                    <div class="pt-3 pb-3 px-2 w-full">
                        <q-btn expand="block" @click="Submit" :disabled="isSubmitting || !isResponsibilityAccepted">SUBMIT</q-btn>
                    </div>

                    <!-- Errors Card (Conditional Rendering if Errors Exist) -->
                    <q-card v-if="errors.length > 0">
                        <template #header>
                            <div class="text-2xl font-bold">Errors</div>
                        </template>
                        <template #main class="q-pt-none">
                            <div>
                                <ul>
                                    <!-- Display Errors -->
                                    <li v-for="(err, index) in errors" :key="index">
                                        <span class="inline-block mb-2 font-bold text-red-500">{{err.field}} - </span> <span class="inline-block w-1/3">{{err.msg}}</span>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </q-card>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    loadingController,
    alertController
} from '@ionic/vue';
import {
    defineComponent
} from 'vue';
import useVuelidate from '@vuelidate/core'
import {
    required,
    requiredIf,
    email,
    minLength,
    minValue,
    numeric
} from '@vuelidate/validators'
import camera from '../../components/Camera.vue'
import {useMainStore} from "@/store/mainStore";
import {usePreWorkStore} from "@/store/preWorkStore";
import { mapStores } from 'pinia'
import PhotoCapture from "@/components/PhotoCapture.vue";
import UserChip from "@/components/UserChip.vue";
import * as Sentry from "@sentry/vue";
import ButtonCheck from "@/components/ButtonCheck.vue";
import FieldSection from "@/components/FieldSection.vue";
import HelpText from "@/components/HelpText.vue";
import ButtonRadio from "@/components/ButtonRadio.vue";

export default defineComponent({
    name: 'Home',
    components: {
        ButtonRadio,
        HelpText,
        FieldSection,
        UserChip,
        PhotoCapture,
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonBackButton,
        camera
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            layout: "blocked",
            isResponsibilityAccepted: true,
            photoType: null,
            currentImages: [],
            loading: false,
            cameraOpen: false,
            jobs: [],
            jobIdOptions: [],
            selectedJob: null,
            jobLoaded: false,
            operators: [],
            staff: [],
            generatedNotes: [],
            dashlights: [],
            customerLocations: ["Leaving - Collect same day", "Leaving - Collect when done", "Leaving - Long term project", "Waiting in Reception", "Staying in Hotel"],
            tyreWidths: ['135', '140', '145', '150', '155', '160', '165', '170', '175', '180', '185', '190', '195', '200', '205', '210', '215', '220', '225', '230', '235', '240', '245', '250', '255', '260', '265', '270', '275', '280', '285', '290', '295', '300', '305', '310', '315', '320', '325'],
            tyreDepths: ['25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85'],
            tyreSizes: ['13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
            requiredImages: [],
            form: usePreWorkStore(),
            isSubmitting: false,
            externalPhotos: [
                {id: 1, text: "Windscreen"},
                {id: 2, text: "Front Drivers Wheel"},
                {id: 3, text: "Sill Right"},
                {id: 4, text: "Front Drivers Corner"},
                {id: 5, text: "Front"},
                {id: 6, text: "Front Passenger Corner"},
                {id: 7, text: "Passenger Side"},
                {id: 8, text: "Front Passenger Wheel"},
                {id: 9, text: "Sill Left"},
                {id: 10, text: "Rear Passenger Wheel"},
                {id: 11, text: "Rear Passenger Corner"},
                {id: 12, text: "Rear"},
                {id: 13, text: "Boot"},
                {id: 14, text: "Rear Drivers Corner"},
                {id: 15, text: "Rear Drivers Wheel"},
                {id: 16, text: "Drivers Side"},
                {id: 17, text: "Under Bonnet"},
                {id: 18, text: "Under Cover"},
                {id: 19, text: "VIN"}
            ],
            interiorPhotos: [
                {id: 1, text: "Interior Front"},
                {id: 2, text: "Interior Rear"},
                {id: 3, text: "Clocks While Running"},
                {id: 4, text: "Extra"}
            ],
            courtesyCarPhotos: [
                {id: 1, text: "Windscreen"},
                {id: 2, text: "Front Drivers Wheel"},
                {id: 3, text: "Front Drivers Corner"},
                {id: 4, text: "Front"},
                {id: 5, text: "Front Passenger Corner"},
                {id: 6, text: "Passenger Side"},
                {id: 7, text: "Front Passenger Wheel"},
                {id: 8, text: "Rear Passenger Wheel"},
                {id: 9, text: "Rear Passenger Corner"},
                {id: 10, text: "Rear"},
                {id: 11, text: "Boot"},
                {id: 12, text: "Rear Drivers Corner"},
                {id: 13, text: "Rear Drivers Wheel"},
                {id: 14, text: "Drivers Side"},
                {id: 15, text: "Under Bonnet"},
                {id: 16, text: "Under Cover"},
                {id: 17, text: "VIN"}
            ],
            errors: []
        }
    },
    validations() {
        return {
            form: {
                reg: {
                    required
                },
                name: {
                    required
                },
                email: {
                    required,
                    email
                },
                contactNum: {
                    required,
                    numeric
                },
                customerLocation: {
                    required
                },
                customerLocationConfirmed: {
                    required
                },
                customerSignature: {
                    required
                },
                customerPresent: {
                    required
                },
                checkCompletedBy: {
                    required
                },
                oldParts: {
                    required
                },
                hasEmissionWork: {
                    required
                },
                smokeLevel: {
                    required: requiredIf(function () {
                        return this.selectedJob && this.selectedJob.tuning === true
                    })
                },
                lockingWheelNutLocation: {
                    required
                },
                dashcamFitted: {
                    required
                },
                hasBlackbox: {
                    required
                },
                hasGhostAlarm: {
                    required
                },
                hasGhostAlarmCode: {
                    required: requiredIf(function () {
                        return this.form.hasGhostAlarmCode === 'Yes'
                    })
                },
                partsConfirmed: {
                    required
                },
                tuningConfirmed: {
                    required: requiredIf(function () {
                        return this.selectedJob && this.selectedJob.tuning === true
                    })
                },
                serviceIndicatorPresent: {
                    required
                },
                hasCourtesyCar: {
                    required
                },
                currentMileage: {
                    required,
                    minValue: minValue(0)
                },
                bookingFormID: {
                    required
                },
                responsibilityConfirmed: {
                    required: requiredIf(function () {
                        return this.form.hasEmissionWork === 'Yes'
                    })
                },
                jobOwner: {},
                tuningOwner: {},
                deposit: {},
                generalConditionOfTyres: {
                    required
                },
                generalConditionOfTyresDesc: {},
                authorisedCosts: {
                    required
                },
                expectedPower: {
                    required: requiredIf(function () {
                        return this.selectedJob && this.selectedJob.tuning === true
                    }),
                    numeric
                },
                launch: {
                    required: requiredIf(function () {
                        return this.selectedJob && this.selectedJob.tuning === true
                    })
                },
                hardcut: {
                    required: requiredIf(function () {
                        return this.selectedJob && this.selectedJob.tuning === true
                    })
                },
                turboNoise: {
                    required
                },
                contactMethod: {
                    required
                },
                notes: {},
                lightsOnDashboardCheck: {
                    required
                },
                dashLights: {
                    required: requiredIf(function () {
                        return this.form.lightsOnDashboardCheck === 'Yes'
                    })
                },
                launchRpm: {
                    required: requiredIf(function () {
                        return this.form.launch === 'Yes'
                    })
                },
                hardcutRpm: {
                    required: requiredIf(function () {
                        return this.form.hardcut === 'Yes'
                    })
                },
                tyreWidth: {
                    required: requiredIf(function () {
                        return this.form.generalConditionOfTyres === 'Red' || this.form.generalConditionOfTyres === 'Orange'
                    })
                },
                tyreDepth: {
                    required: requiredIf(function () {
                        return this.form.generalConditionOfTyres === 'Red' || this.form.generalConditionOfTyres === 'Orange'
                    })
                },
                tyreDiameter: {
                    required: requiredIf(function () {
                        return this.form.generalConditionOfTyres === 'Red' || this.form.generalConditionOfTyres === 'Orange'
                    })
                },
                tyreBrand: {
                    required: requiredIf(function () {
                        return this.form.generalConditionOfTyres === 'Red' || this.form.generalConditionOfTyres === 'Orange'
                    })
                },
                lockingWheelNutLocationOther: {
                    required: requiredIf(function () {
                        return this.form.lockingWheelNutLocation === 'Other'
                    })
                },
                exteriorPhotos: {
                    required,
                    minLength: minLength(1),
                },
                interiorPhotos: {
                    required,
                    minLength: minLength(1),
                },
                courtesyCarPhotos: {
                    required: requiredIf(function () {
                        return this.form.hasCourtesyCar === 'Yes'
                    }),
                    minLength: minLength(1),
                }
            }
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        ...mapStores(usePreWorkStore),
    },
    methods: {
        init() {
            this.form.exteriorPhotos = [];
            this.form.interiorPhotos = [];
            this.form.courtesyCarPhotos = [];
            this.GetOperators()
            this.form.checkCompletedBy = useMainStore().authUser.value
            this.$axios.get('https://darkside-developments.com/vehiclechecklists/common/images/lights/list.php?dir=vw').then((resp) => {
                this.dashlights = resp.data;
            })
            if(this.form.jobId !== this.$router.currentRoute.value.params.id || !this.form.make) {
                this.form.jobId = this.$router.currentRoute.value.params.id
                this.GetStaff().then(async () => {
                    await this.GetJobById(this.$router.currentRoute.value.params.id).catch(err => {
                        console.log(err)
                    })
                })
            } else {
                //const vag = ['vw', 'volkswagen', 'volkswagen commercial', 'seat', 'skoda', 'audi'];
                //if (vag.includes(this.form.make.toLowerCase())) {
                //    this.$axios.get('https://darkside-developments.com/vehiclechecklists/common/images/lights/list.php?dir=vw').then((resp) => {
                //        this.dashlights = resp.data;
                //    })
                //}
                //if (this.form.make.toLowerCase() === 'bmw') {
                //    this.$axios.get('https://darkside-developments.com/vehiclechecklists/common/images/lights/list.php?dir=bmw').then((resp) => {
                //        this.dashlights = resp.data;
                //    })
                //}
            }
            for (const k in this.form) {
                this.$watch('form.' + k, (val, oldVal) => {
                    this.NoteUpdater(k, val, oldVal);
                })
            }
        },
        UpdateLayout(a) {
            var val = a.detail.checked;
            if (val) {
                this.layout = "condensed";
            } else {
                this.layout = "blocked";
            }
        },
        OpenCamera(type) {
            this.photoType = type;
            if (type === "exterior") {
                this.requiredImages = [
                    {id: 1, text: "Windscreen"},
                    {id: 2, text: "Front Drivers Corner"},
                    {id: 3, text: "Front Drivers Wheel"},
                    {id: 4, text: "Front"},
                    {id: 5, text: "Front Passenger Corner"},
                    {id: 6, text: "Front Passenger Wheel"},
                    {id: 7, text: "Passenger Side"},
                    {id: 8, text: "Rear Passenger Corner"},
                    {id: 9, text: "Rear Passenger Wheel"},
                    {id: 10, text: "Rear"},
                    {id: 11, text: "Boot"},
                    {id: 12, text: "Rear Drivers Corner"},
                    {id: 13, text: "Rear Drivers Wheel"},
                    {id: 14, text: "Drivers Side"},
                    {id: 15, text: "Under Bonnet"},
                    {id: 16, text: "Under Cover"},
                    {id: 17, text: "VIN"},
                    {id: 18, text: "Extra"}
                ];
                this.currentImages = this.form.exteriorPhotos
            }
            if (type === "interior") {
                this.requiredImages = [
                    {id: 1, text: "Interior Front"},
                    {id: 2, text: "Interior Rear"},
                    {id: 3, text: "Clocks While Running"},
                    {id: 4, text: "Extra"}
                ];
                this.currentImages = this.form.interiorPhotos
            }

            if (type === "courtesyCar") {
                this.requiredImages = [
                    {id: 1, text: "Windscreen"},
                    {id: 2, text: "Front Drivers Corner"},
                    {id: 3, text: "Front Drivers Wheel"},
                    {id: 4, text: "Front"},
                    {id: 5, text: "Front Passenger Corner"},
                    {id: 6, text: "Front Passenger Wheel"},
                    {id: 7, text: "Passenger Side"},
                    {id: 8, text: "Rear Passenger Corner"},
                    {id: 9, text: "Rear Passenger Wheel"},
                    {id: 10, text: "Rear"},
                    {id: 11, text: "Boot"},
                    {id: 12, text: "Rear Drivers Corner"},
                    {id: 13, text: "Rear Drivers Wheel"},
                    {id: 14, text: "Drivers Side"},
                    {id: 15, text: "Under Bonnet"},
                    {id: 16, text: "Under Cover"},
                    {id: 17, text: "VIN"},
                    {id: 18, text: "Extra"}
                ];
                this.currentImages = this.form.courtesyCarPhotos
            }
            this.cameraOpen = !this.cameraOpen
        },
        CameraClosed(images) {
            this.cameraOpen = false
            if (this.photoType === "exterior") {
                this.form.exteriorPhotos.concat(images)
            }
            if (this.photoType === "interior") {
                this.form.interiorPhotos.concat(images)
            }

            if (this.photoType === "courtesyCar") {
                this.form.courtesyCarPhotos.concat(images)
            }
            this.photoType = null;
            setTimeout(() => {
                this.handleScroll();
            }, 500);
        },
        GetOperators() {
            this.operators = []
            this.$axios.get(this.$apiUrl + '/api/operators/prework').then((resp) => {
                resp.data.forEach(element => {
                    this.operators.push({
                        value: element.id,
                        label: element.nicename
                    })
                });
            })
        },
        GetJobs() {
            this.$axios.get(this.$apiUrl + '/v2/checklist/jobs').then((resp) => {
                this.jobs = resp.data;
                this.jobs.forEach(el => {
                    this.jobIdOptions.push({
                        label: el.title,
                        value: el.id
                    });
                })
            })
        },
        GetJobById(id) {
            return new Promise((resolve, reject) => {
                this.presentLoading()
                const jobid = id;
                this.$axios.get(this.$apiUrl + '/v2/checklist/job/' + jobid).then(async (resp) => {
                    await this.$axios.get(this.$apiUrl + '/v2/checklist/job/folder/' + jobid).then(response => {
                        if (response.data && response.data.success) {
                            this.form.folderId = response.data.message;
                        } else {
                            this.form.folderId = this.$checklistTempFolder;//'0B-RqqvwYVL1FYnA5VktBWjhiQlU';
                            alert('NO FOLDER FOR JOB');
                        }
                    }).catch((err) => {
                        alert('NO FOLDER FOR JOB');
                        this.form.folderId = this.$checklistTempFolder;//'0B-RqqvwYVL1FYnA5VktBWjhiQlU';
                    }).finally(async () => {
                        this.Populate(resp.data);
                        await this.loading.dismiss()
                        resolve();
                    })
                }).catch(async (err) => {
                    if(this.loading) {
                        await this.loading.dismiss()
                    }
                    reject();
                })
            })
        },
        GetJob(resp, e) {
            this.loading = true;
            const jobid = e.value;
            this.$axios.get(this.$apiUrl + '/v2/checklist/job/' + jobid).then((resp) => {
                this.$axios.get(this.$apiUrl + '/v2/checklist/job/folder/' + jobid).then(response => {
                    if (response.data && response.data.success) {
                        this.form.folderId = response.data.message;
                    } else {
                        this.form.folderId = this.$checklistTempFolder;//'0B-RqqvwYVL1FYnA5VktBWjhiQlU';
                    }
                })
                this.Populate(resp.data);
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        },
        Populate(job) {
            this.jobLoaded = true;
            this.selectedJob = job;
            this.form.customerId = job.customer_id;
            this.form.bookingFormID = job.id.toString();
            this.form.name = job.customer.firstname + ' ' + job.customer.lastname;
            this.form.email = job.customer.email.email;
            this.form.jobOwner = this.GetUsername(job.owner);
            this.form.responsibilityConfirmed = 'Decline';
            this.form.tuningOwner = this.GetUsername(job.default_tuning_owner);
            this.form.contactNum = job.customer?.phone?.phonenumber.toString();
            if (job.customer.customer_contact_pref && job.customer.customer_contact_pref.method && job.customer.customer_contact_pref.method.length > 2) {
                const method = job.customer.customer_contact_pref.method.toLowerCase();
                this.form.contactMethod = method[0].toUpperCase() + method.slice(1);//job.customer.customer_contact_pref.method;
            } else {
                this.form.contactMethod = null;
            }
            this.form.jobsheetLink = 'https://jobs.darkside-developments.com/jobsheet/' + job.id;
            this.form.tuningJobLink = 'https://jobs.darkside-developments.com/tuning/' + job.id;

            if (job.vehicle) {
                this.form.reg = job.vehicle.VRVrm || '';
                this.form.launch = job.vehicle.customer_launch || '';
                this.form.hardcut = job.vehicle.customer_hardcut || '';
                this.form.expectedPower = job.vehicle.customer_expected_power || '';
                this.form.smokeLevel = job.vehicle.customer_smoke_level || '';
                this.form.make = job.vehicle.VRMake || '';
                this.form.model = job.vehicle.VRModel || '';
                const vag = ['vw', 'volkswagen', 'volkswagen commercial', 'seat', 'skoda', 'audi'];
                if (vag.includes(this.form.make.toLowerCase())) {
                    this.$axios.get('https://darkside-developments.com/vehiclechecklists/common/images/lights/list.php?dir=vw').then((resp) => {
                        this.dashlights = resp.data;
                    })
                }
                if (this.form.make.toLowerCase() === 'bmw') {
                    this.$axios.get('https://darkside-developments.com/vehiclechecklists/common/images/lights/list.php?dir=bmw').then((resp) => {
                        this.dashlights = resp.data;
                    })
                }
            }
            this.form.deposit = job.deposit.toString() || '';
            this.form.customerLocation = job.customer_activity || '';
            this.form.notes = job.note || '';
            /*setTimeout(() => {
                this.$refs.notes.setContent(this.form.notes)
            }, 500);*/
        },
        GetStaff() {
            return new Promise((resolve, reject) => {
                this.$axios.get(this.$apiUrl + '/api/users/all').then((resp) => {
                    this.staff = resp.data;
                    this.staff.push({
                        "id": 42,
                        "username": "Workshop",
                        "nicename": "Workshop"
                    })
                    this.staff.push({
                        "id": 44,
                        "username": "Tuning",
                        "nicename": "Tuning"
                    })
                    this.staff.push({
                        "id": 99,
                        "username": "NA",
                        "nicename": "NA"
                    })
                    this.staff.sort(this.NicenameAlphabetical);
                    resolve()
                })
            });
        },
        NicenameAlphabetical(a, b) {
            const A = a.nicename.toUpperCase();
            const B = b.nicename.toUpperCase();

            let comparison = 0;
            if (A > B) {
                comparison = 1;
            } else if (A < B) {
                comparison = -1;
            }
            return comparison;
        },
        GetUsername(id) {
            if (id) {
                const user = this.staff.find(el => el.id === id);
                if (user === undefined) {
                    return ''
                }
                return user.nicename;
            } else {
                return '';
            }
        },
        handleScroll() {
            let element = document.getElementById('ext_checks');
            element.scrollIntoView({
                behavior: 'smooth'
            });
        },
        NoteUpdater(name, val, old) {
            if (name.includes('desc')) {
                name = name.replace('_desc', '')
                val = this.form[name];
            }
            const fieldname = name;
            const field = this.warningFields;
            const warn = field[name];
            const title = name.replace(/_/g, ' ');
            name = title.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            if (Array.isArray(val)) {
                val = this.$_.join(val, ',');
                const checkboxes = document.querySelectorAll('[name="' + fieldname.toLowerCase() + '"]:checked');
                if (checkboxes.length > 0) {
                    val = Array.prototype.slice.call(checkboxes).map(x => x.value).join(',');
                }
            }
            if (warn) {
                switch (warn.warnType) {
                    case 'interval':
                        this.generatedNotes = this.NoteIntervalUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'values':
                        this.generatedNotes = this.NoteValuesUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'value':
                        this.generatedNotes = this.NoteValueUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'optnotes':
                        this.generatedNotes = this.NoteOptNotesUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'optvalues':
                        this.generatedNotes = this.NoteOptValuesUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'valmsg':
                        this.generatedNotes = this.NoteValMessageUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'notes':
                        console.log('has - notes')
                        break;
                    case 'optnotesfocus':
                        console.log('has - optnotesfocus')
                        break;
                    case 'psi':
                        console.log('has - psi')
                        break;
                    default:
                        break;
                }
            }
        },
        AddLightToList(light) {
            let type = null;
            if (light.type === null) {
                type = 'Orange';
            } else if (light.type === 'Orange') {
                type = 'Red';
            } else {
                type = null;
            }
            const indx = this.dashlights.findIndex(el => el.id === light.id);
            if (indx > -1) {
                this.dashlights[indx].type = type;
            }

            const find = this.form.dashLights.findIndex(el => el.name === light.name);
            if (find > -1) {
                if (type === null) {
                    this.form.dashLights.splice(find, 1);
                } else {
                    this.form.dashLights[find].type = type;
                }
            } else {
                this.form.dashLights.push({
                    name: light.name,
                    type: type
                });
            }

            this.generatedNotes.dashlights = this.form.dashLights
        },
        isResponsibilityRequired() {
            this.isResponsibilityAccepted = this.form.hasEmissionWork !== 'Yes';
        },
        Accepted() {
            this.isResponsibilityAccepted = this.form.responsibilityConfirmed === 'Accept';
        },
        GetDashLightClass(light) {
            if (light.type === null) {
                return 'bg-white';
            } else if (light.type === 'Orange') {
                return 'bg-yellow-500';
            } else {
                return 'bg-red-500';
            }
        },
        ConvertNote(str) {
            var splitStr = str.split(' ');
            var first = splitStr[0];
            var join = str.replace(first, '');
            return this.$_.upperFirst(this.$_.lowerCase(first)) + " " + join;
        },
        SaveSignature(data) {
            this.form.customerSignature = data;
        },
        async Submit() {
            try {
                this.isSubmitting = true;
                if(!this.$refs.sigpad.signaturePad.isEmpty()) {
                    this.form.customerSignature = this.$refs.sigpad.signaturePad.toDataURL("image/png")
                } else {
                    this.form.customerSignature = null
                }
                this.v$.$touch()
                const errors = [];
                for (const [key, value] of Object.entries(this.v$.form)) {
                    if (!key.includes("$")) {
                        if (value.$error) {
                            if (value.$invalid === true) {
                                for (let key2 in this.$options.messages) {
                                    var fieldName = "";
                                    if (value[key2]) {
                                        fieldName = this.$_.startCase(key)
                                        if (fieldName === "Photos Exterior Photos") {
                                            fieldName = fieldName.replace('Photos Exterior Photos', 'Exterior Photos')
                                        }
                                        if (fieldName === "Photos Interior Photos") {
                                            fieldName = fieldName.replace('Photos Interior Photos', 'Interior Photos')
                                        }
                                        errors.push({
                                            field: fieldName,
                                            msg: this.$options.messages[key2](value[key2]) //value[key2].$message
                                        })
                                    }
                                }
                            }
                        }
                    }
                }

                if (errors.length > 0) {
                    for (let index = 0; index < errors.length; index++) {
                        const element = errors[index];
                        if(element.field === "Exterior Photos" || element.field === "Interior Photos") {
                            if(element.msg === "This field is required") {
                                errors.splice(index, 1);
                            }
                        }
                    }
                    this.errors = errors
                    //await document.querySelector('ion-content').scrollToTop(500);
                    this.isSubmitting = false;
                } else {
                    await this.presentLoading();
                    const form_data = new FormData();
                    for ( let key in this.form.$state ) {
                        /*if(key === "exteriorPhotos" || key === "interiorPhotos" || key === "courtesyCarPhotos") {
                            for ( let key2 in this.form.$state[key] ) {
                                for (const key3 in this.form.$state[key][key2]) {
                                    form_data.append(key + "[" +key2+"]" + "["+key3+"]", this.form.$state[key][key2][key3]);
                                }
                            }
                        } else if(key === "dashLights") {
                            form_data.append(key, JSON.stringify(this.form.$state[key]));
                        } else if(key === "customerSignature") {
                            form_data.append("customerSignature[0][name]", "Signature");
                            form_data.append("customerSignature[0][type]", "Pre Work");
                            const blobfile = this.dataURLToBlob(this.form.customerSignature);
                            const file = new File([blobfile], 'signature.png', {
                                type: "image/png",
                                lastModified: new Date().getTime()
                            });
                            form_data.append("customerSignature[0][image]", file);
                        } else {
                            form_data.append(key, this.form.$state[key]);
                        }*/
                        if(key === "dashLights") {
                            form_data.append(key, JSON.stringify(this.form.$state[key]));
                        } else if(key === "customerSignature") {
                            form_data.append("customerSignature[0][name]", "Signature");
                            form_data.append("customerSignature[0][type]", "Pre Work");
                            const blobfile = this.dataURLToBlob(this.form.customerSignature);
                            const file = new File([blobfile], 'signature.png', {
                                type: "image/png",
                                lastModified: new Date().getTime()
                            });
                            form_data.append("customerSignature[0][image]", file);
                        } else {
                            form_data.append(key, this.form.$state[key]);
                        }
                    }

                    form_data.append("generatedNotes", document.getElementById("generatedNotes").innerText.replaceAll('\n', '<br/>'));
                    this.$axios.post(this.$apiUrl + '/v2/checklist/complete/prework', form_data).then(async (resp) => {
                        await this.loading.dismiss()
                        this.loading = false
                        await this.presentAlert("Complete", "Form successfully submitted!", true)
                        this.isSubmitting = false;
                    }).catch(async (err) => {
                        console.log(err)
                        await this.loading.dismiss()
                        this.loading = false
                        await this.presentAlert("Error", "Form failed to submit!", false)
                        this.isSubmitting = false;
                    })
                }
            } catch(err) {
                console.log(err)
                Sentry.captureException(err);
            }
        },
        async presentLoading() {
            this.loading = await loadingController.create({
                cssClass: 'my-custom-class',
                message: 'Please wait...'
            });
            await this.loading.present();
        },
        async presentAlert(header, msg, redirect = false) {
            const alert = await alertController.create({
                cssClass: 'my-custom-class',
                header: header,
                message: msg
            });
            await alert.present();

            const {
                role
            } = await alert.onDidDismiss();
            if (redirect) {
                this.$router.push('/checklists')
            }
            console.log('onDidDismiss resolved with role', role);
        },
        async clearAndReload(redirect = false) {
            this.$refs.sigpad.signaturePad.clear()
            await this.preWorkStoreStore.clearAll();
            this.layout = "blocked";
            this.photoType = null;
            this.loading = false;
            this.cameraOpen = false;
            this.selectedJob = null;
            this.jobLoaded = false;
            this.isSubmitting = false;
            this.currentImages = [];
            this.jobs = [];
            this.jobIdOptions = [];
            this.operators = [];
            this.staff = [];
            this.generatedNotes = [];
            this.dashlights = [];
            this.requiredImages = [];
            this.errors = [];
            this.form = this.preWorkStoreStore;
            if(redirect) {
                this.$router.push('/checklists')
            } else {
                this.init();
            }
        },
        exteriorImageTaken(result) {
            const imageCount = this.form.exteriorPhotos.filter(el => el.name === result.name);
            let filename = result.name;
            if(imageCount.length > 0) {
                filename = result.name + " (" + imageCount.length + ")"
            }

            const form_data = new FormData();
            form_data.append("image", result.image);
            form_data.append("image_name", filename);
            form_data.append("checklist_type", 'Pre Work');
            form_data.append("job_id", this.selectedJob.id);

            this.$axios.post(this.$apiUrl + '/v2/checklist/upload/image', form_data).then(async (resp) => {
                console.log(resp)
            }).catch(async (err) => {
                console.log(err)
            })
            this.form.exteriorPhotos.push(result)
        },
        interiorImageTaken(result) {
            const imageCount = this.form.interiorPhotos.filter(el => el.name === result.name);
            let filename = result.name;
            if(imageCount.length > 0) {
                filename = result.name + " (" + imageCount.length + ")"
            }

            const form_data = new FormData();
            form_data.append("image", result.image);
            form_data.append("image_name", filename);
            form_data.append("checklist_type", 'Pre Work');
            form_data.append("job_id", this.selectedJob.id);

            this.$axios.post(this.$apiUrl + '/v2/checklist/upload/image', form_data).then(async (resp) => {
                console.log(resp)
            }).catch(async (err) => {
                console.log(err)
            })
            this.form.interiorPhotos.push(result)
        },
        courteseyCarImageTaken(result) {
            const imageCount = this.form.courtesyCarPhotos.filter(el => el.name === result.name);
            let filename = result.name;
            if(imageCount.length > 0) {
                filename = result.name + " (" + imageCount.length + ")"
            }

            const form_data = new FormData();
            form_data.append("image", result.image);
            form_data.append("image_name", filename);
            form_data.append("checklist_type", 'Pre Work');
            form_data.append("job_id", this.selectedJob.id);

            this.$axios.post(this.$apiUrl + '/v2/checklist/upload/image', form_data).then(async (resp) => {
                console.log(resp)
            }).catch(async (err) => {
                console.log(err)
            })
            this.form.courtesyCarPhotos.push(result)
        },
    },
    messages: {
        required: v => 'This field is required',
        email: v => 'Must be a valid e-mail',
        alphaNum: v => 'May only contain letters and numbers',
        minLength: v => 'Must be at least ' + v.$params.min + ' photos',
        goodPassword: v => '', //The password rules are always displayed, so no need to show a message
        sameAs: v => 'Must be same as ' + v.$params.sameAs.eq
    }
});
</script>

<style lang="scss" scoped>
div.blocked {
    display: flex;

    >label {
        width: 40%;
    }

    >div {
        width: 60%
    }
}

.checklist-notes {
    min-height: 100px;
}
</style>