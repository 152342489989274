<template>
    <ion-page>
        <ion-header :translucent="false">
            <ion-toolbar>
                <ion-title class="font-bold">Workshop Extra Photos & Videos</ion-title>
                <span slot="end" class="pr-5">
                    <user-chip></user-chip>
                </span>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <ion-header collapse="condense">
                <ion-toolbar>
                    <ion-title size="large">Blank</ion-title>
                </ion-toolbar>
            </ion-header>

            <div id="container">
                <div class="min-h-screen h-full" id="top">
                    <q-card class="mt-3" v-if="errors.length > 0">
                        <template #header>
                            <div class="text-2xl font-bold">Errors</div>
                        </template>
                        <template #main class="q-pt-none">
                            <div>
                                <ul>
                                    <li v-for="(err, index) in errors" :key="index">
                                        <span class="inline-block mb-2 font-bold text-red-500">{{err.field}} - </span> <span class="inline-block w-32">{{err.msg}}</span>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </q-card>

                    <q-card class="mt-3">
                        <template #header>
                            <div class="text-2xl font-bold">General</div>
                        </template>
                        <template #main class="q-pt-none">
                            <field-section class="mt-4" title="Registration Number">
                                <q-input v-model="v$.form.reg.$model" :v="v$.form.reg" readonly/>
                            </field-section>

                            <field-section class="mt-10" title="Customer Name">
                                <q-input v-model="v$.form.name.$model" :v="v$.form.name" readonly/>
                            </field-section>

                            <field-section class="mt-10" title="Notes">
                                <q-editor v-model="v$.form.notes.$model" :v="v$.form.notes" min-height="5rem" />
                            </field-section>
                        </template>
                    </q-card>

                    <q-card class="mt-3">
                        <template #header>
                            <div class="text-2xl font-bold">Photos/Videos</div>
                        </template>
                        <template #main class="q-pt-none">
                            <div class="block">
                                <div v-for="(photo, index) in form.extraPhotos" :key="index">
                                    <div class="flex gap-3">
                                        <q-input class="w-64" v-model="photo.filename" placeholder="Filename..."></q-input>
                                        <q-input v-model="photo.note" placeholder="Note..."></q-input>
                                        <q-btn size="small" @click="OpenCameraImage(index, $event)" :disabled="photo.image !== ''">
                                            <i class="ic ic-camera pointer-events-none"></i>
                                            <input :ref="'imgFileInput'+index" class="h-0 w-0" type="file" capture="user" accept="image/*" @change="UpdateImgData(index, $event)">
                                            <span class="ml-2" v-if="photo.image !== ''">Image Taken</span>
                                        </q-btn>
                                        <q-btn class="ml-auto" size="small" fill="outline" @click="RemoveImage(index)">Remove</q-btn>
                                    </div>
                                </div>
                                <q-btn @click="AddPhoto">Add Photo</q-btn>
                            </div>

                            <div class="block mt-10">
                                <div v-for="(video, index) in form.extraVideos" :key="index">
                                    <div class="flex gap-3">
                                        <q-input class="w-64" v-model="video.filename" placeholder="Filename..."></q-input>
                                        <q-input v-model="video.note" placeholder="Note..."></q-input>
                                        <q-btn size="small" @click="OpenCameraVideo(index, $event)" :disabled="video.video !== ''">
                                            <i class="ic ic-video pointer-events-none"></i>
                                            <input :ref="'vidFileInput'+index" class="h-0 w-0" type="file" capture="user" accept="video/*" @change="UpdateVidData(index, $event)">
                                            <span class="ml-2" v-if="video.video !== ''">Video Taken</span>
                                        </q-btn>
                                        <q-btn class="ml-auto" size="small" fill="outline" @click="RemoveVideo(index)">Remove</q-btn>
                                    </div>
                                </div>
                                <q-btn @click="AddVideo">Add Video</q-btn>
                            </div>
                        </template>
                    </q-card>

                    <div class="pt-3 pb-6 px-2 w-full">
                        <q-btn expand="block" @click="Submit">SUBMIT</q-btn>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    alertController,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, loadingController
} from '@ionic/vue';
import {
    defineComponent
} from 'vue';
import useVuelidate from '@vuelidate/core'
import {
    required,
    requiredIf,
    email,
    minValue,
    numeric
} from '@vuelidate/validators'
import {useMainStore} from "@/store/mainStore";
import {useExtraPhotosVideosStore} from "@/store/extraPhotosVideosStore";
export default defineComponent({
    name: 'Home',
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            layout: "blocked",
            photoType: null,
            currentImages: [],
            loading: false,
            cameraOpen: false,
            jobs: [],
            jobIdOptions: [],
            selectedJob: null,
            jobLoaded: false,
            operators: [],
            staff: [],
            generatedNotes: [],
            jobslist: [],
            form: useExtraPhotosVideosStore(),
            errors: [],
        }
    },
    validations() {
        return {
            form: {
                reg: {
                    required
                },
                name: {
                    required
                },
                notes: {}
            }
        }
    },
    async mounted() {
        await this.presentLoading()
        this.form.checkCompletedBy = useMainStore().authUser.value
        this.form.jobId = this.$router.currentRoute.value.params.id
        this.GetOperators()
        this.GetStaff().then(() => {
            this.GetJobById(this.$router.currentRoute.value.params.id)
        })
        for (const k in this.form) {
            this.$watch('form.' + k, (val, oldVal) => {
                this.NoteUpdater(k, val, oldVal);
            })
        }
    },
    methods: {
        UpdateLayout(a) {
            var val = a.detail.checked;
            if (val) {
                this.layout = "condensed";
            } else {
                this.layout = "blocked";
            }
        },
        GetOperators() {
            this.operators = []
            this.$axios.get(this.$apiUrl + '/api/operators/prework').then((resp) => {
                resp.data.forEach(element => {
                    this.operators.push({
                        value: element.id,
                        label: element.nicename
                    })
                });
            })
        },
        GetJobs() {
            this.$axios.get(this.$apiUrl + '/v2/checklist/jobs').then((resp) => {
                this.jobs = resp.data;
                this.jobs.forEach(el => {
                    this.jobIdOptions.push({
                        label: el.title,
                        value: el.id
                    });
                })
            })
        },
        GetJobById(id) {
            const jobid = id;
            this.$axios.get(this.$apiUrl + '/v2/checklist/job/' + jobid).then(async (resp) => {
                this.$axios.get(this.$apiUrl + '/v2/checklist/job/folder/' + jobid).then(response => {
                    if (response.data && response.data.success) {
                        this.form.folderId = response.data.message;
                    } else {
                        this.form.folderId = this.$checklistTempFolder;//'0B-RqqvwYVL1FYnA5VktBWjhiQlU';
                    }
                })
                this.Populate(resp.data);
                await this.loading.dismiss();
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        },
        GetJob(resp, e) {
            this.loading = true;
            const jobid = e.value;
            this.$axios.get(this.$apiUrl + '/v2/checklist/job/' + jobid).then((resp) => {
                this.$axios.get(this.$apiUrl + '/v2/checklist/job/folder/' + jobid).then(response => {
                    if (response.data && response.data.success) {
                        this.form.folderId = response.data.message;
                    } else {
                        this.form.folderId = this.$checklistTempFolder;//'0B-RqqvwYVL1FYnA5VktBWjhiQlU';
                    }
                })
                this.Populate(resp.data);
                this.loading = false;
            }).catch(() => {
                this.loading = false;
            })
        },
        Populate(job) {
            if (job.folder_id === null || job.folder_id === '') {
                alert('NO FOLDER FOR JOB');
            }
            this.jobLoaded = true;
            this.form.customerId = job.customer_id;
            this.form.name = job.customer.firstname + ' ' + job.customer.lastname;
            this.form.email = job.customer.email.email;
            if (job.vehicle) {
                this.form.reg = job.vehicle.VRVrm || '';
            }
            this.form.notes = job.note || '';
        },
        GetStaff() {
            return new Promise((resolve, reject) => {
                this.$axios.get(this.$apiUrl + '/api/users/all').then((resp) => {
                    this.staff = resp.data;
                    this.staff.push({
                        "id": 42,
                        "username": "Workshop",
                        "nicename": "Workshop"
                    })
                    this.staff.push({
                        "id": 44,
                        "username": "Tuning",
                        "nicename": "Tuning"
                    })
                    this.staff.push({
                        "id": 99,
                        "username": "NA",
                        "nicename": "NA"
                    })
                    this.staff.sort(this.NicenameAlphabetical);
                    resolve()
                })
            });
        },
        NicenameAlphabetical(a, b) {
            const A = a.nicename.toUpperCase();
            const B = b.nicename.toUpperCase();

            let comparison = 0;
            if (A > B) {
                comparison = 1;
            } else if (A < B) {
                comparison = -1;
            }
            return comparison;
        },
        GetUsername(id) {
            if (id) {
                const user = this.staff.find(el => el.id === id);
                if (user === undefined) {
                    return ''
                }
                return user.nicename;
            } else {
                return '';
            }
        },
        NoteUpdater(name, val, old) {
            if (name.includes('desc')) {
                name = name.replace('_desc', '')
                val = this.form[name];
            }
            const fieldname = name;
            const field = this.warningFields;
            const warn = field[name];
            const title = name.replace(/_/g, ' ');
            name = title.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            if (Array.isArray(val)) {
                val = this.$_.join(val, ',');
                const checkboxes = document.querySelectorAll('[name="' + fieldname.toLowerCase() + '"]:checked');
                if (checkboxes.length > 0) {
                    val = Array.prototype.slice.call(checkboxes).map(x => x.value).join(',');
                }
            }
            if (warn) {
                switch (warn.warnType) {
                    case 'interval':
                        this.generatedNotes = this.NoteIntervalUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'values':
                        this.generatedNotes = this.NoteValuesUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'value':
                        this.generatedNotes = this.NoteValueUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'optnotes':
                        this.generatedNotes = this.NoteOptNotesUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'optvalues':
                        this.generatedNotes = this.NoteOptValuesUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'valmsg':
                        this.generatedNotes = this.NoteValMessageUpdate(warn, name, val, old, this.generatedNotes, this.form);
                        break;
                    case 'notes':
                        console.log('has - notes')
                        break;
                    case 'optnotesfocus':
                        console.log('has - optnotesfocus')
                        break;
                    case 'psi':
                        console.log('has - psi')
                        break;
                    default:
                        break;
                }
            }
        },
        ConvertNote(str) {
            var splitStr = str.split(' ');
            var first = splitStr[0];
            var join = str.replace(first, '');
            return this.$_.upperFirst(this.$_.lowerCase(first)) + " " + join;
        },
        AddPhoto() {
            this.form.extraPhotos.push({
                filename: "",
                note: "",
                image: "",
            })
        },
        AddVideo() {
            this.form.extraVideos.push({
                filename: "",
                note: "",
                video: "",
            })
        },
        RemoveImage(index) {
            this.form.extraPhotos.splice(index, 1);
        },
        RemoveVideo(index) {
            this.form.extraVideos.splice(index, 1);
        },
        OpenCameraImage(index, event) {
            const ref = "imgFileInput" + index;
            this.$refs[ref][0].click()
        },
        OpenCameraVideo(index, event) {
            const ref = "vidFileInput" + index;
            this.$refs[ref][0].click();
        },
        UpdateImgData(index, event) {
            this.form.extraPhotos[index].media = event.target.files[0];
            //this.GetBase64(event.target.files[0]).then(resp => {
            //    this.form.extraPhotos[index].image = resp;
            //    console.log(this.form.extraPhotos)
            //}).catch(err => {
            //    console.log(err)
            //})
        },
        UpdateVidData(index, event) {
            this.form.extraVideos[index].media = event.target.files[0];
            //this.GetBase64(event.target.files[0]).then(resp => {
            //    this.form.extraVideos[index].video = resp;
            //    console.log(this.form.extraVideos)
            //}).catch(err => {
            //    console.log(err)
            //})
        },
        GetBase64(file) {
            console.log(file)
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    resolve(reader.result)
                };
                reader.onerror = function (error) {
                    reject(error)
                };
            })
        },
        async Submit() {

            this.v$.$touch()
            var errors = [];
            for (const [key, value] of Object.entries(this.v$.form)) {
                if (!key.includes("$")) {
                    if (value.$error) {
                        if (value.$invalid === true) {
                            for (let key2 in this.$options.messages) {
                                if (value[key2]) {
                                    var fieldName = this.$_.startCase(key)
                                    errors.push({
                                        field: fieldName,
                                        msg: value[key2].$message
                                    })
                                }
                            }
                        }
                    }
                }
            }
            if (errors.length > 0) {
                document.querySelector('ion-content').scrollToTop(500);
                this.errors = errors
            } else {
                await this.presentLoading();
                console.log(this.form)
                const form_data = new FormData();
                const extraMedia = this.form.extraPhotos.concat(this.form.extraVideos);
                for (let i = 0; i < extraMedia.length; i++) {
                    const media = extraMedia[i];
                    form_data.append('media['+i+'][name]', media.filename)
                    form_data.append('media['+i+'][image]', media.media)
                    form_data.append('media['+i+'][note]', media.note)
                }
                form_data.append('job_id', this.form.jobId);
                this.$axios.post(this.$apiUrl + '/v2/checklist/complete/extramedia', form_data).then(async (resp) => {
                    console.log(resp.data)
                    await this.loading.dismiss()
                    this.loading = false
                    this.presentAlert("Complete", "Form successfully submitted!", true)
                }).catch(async (err) => {
                    console.log(err)
                    await this.loading.dismiss()
                    this.loading = false
                    this.presentAlert("Error", "Form failed to submit!", false)
                })
            }
        },
        async presentLoading() {
            this.loading = await loadingController.create({
                cssClass: 'my-custom-class',
                message: 'Please wait...'
            });
            await this.loading.present();
        },
        async presentAlert(header, msg, redirect = false) {
            const alert = await alertController.create({
                cssClass: 'my-custom-class',
                header: header,
                message: msg
            });
            await alert.present();

            const {
                role
            } = await alert.onDidDismiss();
            if (redirect) {
                this.$router.push('/checklists')
            }
            console.log('onDidDismiss resolved with role', role);
        },
    },
    messages: {
        required: v => 'This field is required',
        email: v => 'Must be a valid e-mail',
        alphaNum: v => 'May only contain letters and numbers',
        minLength: v => 'Must be at least ' + v.$params.minLength.min + ' characters',
        goodPassword: v => '', //The password rules are always displayed, so no need to show a message
        sameAs: v => 'Must be same as ' + v.$params.sameAs.eq
    }
});
</script>

<style lang="scss" scoped>
div.blocked {
    display: flex;

    >label {
        width: 40%;
    }

    >div {
        width: 60%
    }
}
</style>
